/* Common styles */
.site-map {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.site-map ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.site-map button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s;
}

/* Dark mode styles */
.site-map.dark {
  background-color: #202222;
  color: white;
}

.site-map.dark button {
  color: white;
}

.site-map.dark button:hover {
  color: #2ba9d2;
}

/* Light mode styles */
.site-map.light {
  background-color: #f0f0f0;
  color: #333;
}

.site-map.light button {
  color: #333;
}

.site-map.light button:hover {
  color: #0077b6;
}

@media (max-width: 768px) {
  .site-map {
    display: none;
  }
}
