:root {
  --font-primary: 'Inter', sans-serif;
  --dark-bg: #191A1A;
  --light-bg: #f4f4f4;
  --dark-border: rgba(255, 255, 255, 0.1);
  --light-border: rgba(0, 0, 0, 0.1);
}

body, html {
  font-family: var(--font-primary);
  margin: 0; /* Ensure there's no default margin */
  padding: 0; /* Ensure there's no default padding */
  height: 100%; /* Ensure the body and html elements take full height */
  width: 100%; /* Ensure the body and html elements take full width */
}

/* When there is no navbar, force full width */
.content.full-screen {
  margin-left: 0;
}

body.dark-mode {
  background-color: var(--dark-bg);
}

body.light-mode {
  background-color: var(--light-bg);
}

.App {
  display: flex;
  height: 100vh;
  font-family: var(--font-primary); /* Ensuring the font is applied to the App component */
}

.content {
  padding: 20px;
  font-family: var(--font-primary); /* Ensuring the font is applied to the content */
  flex-grow: 1; /* Take up remaining space after navbar */
  padding: 20px;
  border-left: 1px solid var(--dark-border); /* Default dark mode border */
  border-bottom: 1px solid var(--dark-border); /* Default dark mode border */
  border-radius: 8px; /* Round the border */
  overflow-y: auto;
  transition: border-color 0.3s ease;
}

body.light-mode .content {
  border-left: 1px solid var(--light-border); /* Light mode border */
  border-bottom: 1px solid var(--light-border); /* Light mode border */
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 10px;
  background: #000;
  color: #fff;
  padding: 8px;
  z-index: 100;
  text-decoration: none;
  transition: top 0.3s ease;
}

.skip-link:focus {
  top: 10px;
}
/* Toast popup css */
/* 1. Override the icon color for success toasts */
.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #2ba9d2; /* Desired icon color */
  stroke: #2ba9d2; /* Desired icon color */
}

/* 2. Adjust the background color for better contrast with the new icon color */
.Toastify__toast--success {
  background-color: #333; /* Example dark background */
  color: #fff; /* Text color */
}

/* 3. Override the progress bar color for success toasts */
.Toastify__progress-bar--success {
  background: #2ba9d2; /* Desired progress bar color */
}

/* 4. Customize the height of the progress bar */
.Toastify__progress-bar {
  height: 5px; /* Adjust as needed */
}

/* 5. Custom class for the toast icon */
.toast-custom-icon {
  width: 24px;
  height: 24px;
  object-fit: contain; /* Ensures the image scales correctly */
}

/* 6. Custom class to change cursor on clickable toasts */
.custom-toast-clickable {
  cursor: pointer;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out; /* Smooth transitions */
}

/* 7. Add scaling and opacity changes on hover */
.custom-toast-clickable:hover {
  transform: scale(1.03); /* Slightly enlarge the toast */
  opacity: 0.95; /* Slightly reduce opacity for subtle feedback */
}

/* For screens between 768px and below */
@media (max-width: 768px) {
  .main-content {
    margin-left: 0px; /* Remove left margin for smaller screens */
  }

  .content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    margin-left: 0px; /* Reset margin to remove the space for the navbar */
    margin-bottom: 0px; /* Remove margin for the sitemap */
    border-left: none; /* Remove the left border */
    border-bottom: none; /* Remove the bottom border */
    border-radius: 0px; /* Remove border-radius on small screens */
  }
}
