/* Common settings */
.settings-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 0;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.settings-title-container {
    width: 100%;
    position: relative;
}

.settings-title {
    font-size: 2rem;
    text-align: left;
    padding-bottom: 10px;
    margin-bottom: 30px;
    margin-top: 0;
    width: 100%;
    position: relative;
    top: -20px;
    font-weight: normal;
    transition: color 0.3s ease;
}

.settings-underline {
    width: 100vw;
    border-bottom: 2px solid #3a3a3a;
    position: relative;
    top: -53px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
}

.settings-card {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-top: 60px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.settings-section-title {
    font-size: 1.3rem;
    position: absolute;
    margin-bottom: 20px;
    margin-top: 0;
    top: -42px;
    left: -5px;
    font-weight: normal;
    padding: 0 10px;
    background-color: inherit;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.settings-item {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settings-label {
    font-size: 1rem;
    margin-bottom: 8px;
}

.settings-item span {
    color: inherit;
}

.settings-appearance-toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.settings-appearance-toggle-label {
    font-size: 1rem;
    color: #ccc;
    margin-right: 20px;
}

.settings-toggle-button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    margin-left: auto;
    transition: background-color 0.3s ease;
}

.settings-toggle-button.light {
    background-color: #f0f0f0;
    color: #333;
}

.settings-toggle-button.dark {
    background-color: #444;
    color: #fff;
}

.settings-toggle-button:hover {
    background-color: #666;
    color: #fff;
}

/* Hover effect for signout button */
.settings-signout-button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    margin-left: auto;
    transition: background-color 0.3s ease;
}

.settings-signout-button.light {
    background-color: #f0f0f0;
    color: #333;
}

.settings-signout-button.dark {
    background-color: #444;
    color: #fff;
}

.settings-signout-button:hover {
    background-color: #888;
    color: #000;
}

/* Dark mode (default) */
body.dark-mode {
    background-color: #191A1A;
    color: #ffffff;
}

body.dark-mode .settings-card {
    background-color: #252526;
    color: #ffffff;
}

body.dark-mode .settings-title {
    color: #ffffff;
}

body.dark-mode .settings-section-title {
    background-color: #191A1A;
    color: #ffffff;
}

body.dark-mode .settings-signout-button {
    background-color: #444;
    color: #fff;
}

body.dark-mode .settings-signout-button:hover,
body.dark-mode .settings-toggle-button:hover {
    background-color: #666; /* Darker gray on hover for dark mode */
    color: #fff;
}

/* Light mode */
body.light-mode {
    background-color: #f4f4f4;
    color: #333;
}

body.light-mode .settings-card {
    background-color: #ffffff;
    color: #333;
}

body.light-mode .settings-title {
    color: #333;
}

body.light-mode .settings-section-title {
    background-color: #f4f4f4;
    color: #333;
}

body.light-mode .settings-toggle-button {
    background-color: #f0f0f0;
    color: #333;
}

body.light-mode .settings-toggle-button:hover {
    background-color: #ddd;
    color: #000;
}

body.light-mode .settings-signout-button {
    background-color: #f0f0f0;
    color: #333;
}

body.light-mode .settings-signout-button:hover {
    background-color: #ddd;
    color: #000;
}

/* Styles for the project button and dropdown */

.settings-project-button {
    margin-top: 30px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    position: relative;
    width: 100%;
    max-width: 300px;
    text-align: left;
}

.settings-project-button.light {
    background-color: #f0f0f0;
    color: #333;
}

.settings-project-button.dark {
    background-color: #444;
    color: #fff;
}

.settings-project-button:hover {
    background-color: #666;
    color: #fff;
}

.settings-project-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-width: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.settings-project-dropdown.light {
    background-color: #fff;
}

.settings-project-dropdown.dark {
    background-color: #333;
}

.settings-project-dropdown-item {
    padding: 10px;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    font-size: 1rem;
    cursor: pointer;
}

.settings-project-dropdown-item.light {
    color: #333;
}

.settings-project-dropdown-item.dark {
    color: #fff;
}

.settings-project-dropdown-item:hover {
    background-color: rgb(58, 164, 199);
}

/* Adjust the container to position the dropdown correctly */
.settings-active-project-container {
    position: relative;
    display: inline-block;
}

/* Loading and Error Messages */
.settings-project-loading,
.settings-project-error {
    display: block;
    margin-top: 8px;
    font-size: 0.9rem;
}

.settings-project-loading {
    color: #007BFF;
}

.settings-project-error {
    color: #ff4d4f;
}

/* Responsive adjustments */
@media screen and (max-width: 600px) {
    .settings-project-button {
        width: 100%;
    }
    .settings-project-dropdown {
        width: 100%;
    }
}