/* LibraryModal.css */

/* Modal Overlay */
.library-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it sits above other elements */
}

/* Modal Content */
.library-modal-content {
    background-color: #2c2c2c; /* Dark gray background matching Library component */
    color: #f4f4f4; /* Light text color for readability */
    padding: 30px 40px; /* Increased padding for better spacing */
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    animation: fadeIn 0.3s ease-out;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Modal Title */
.library-modal-content h2 {
    margin-top: 0;
    margin-bottom: 25px; /* Increased margin for better spacing */
    font-size: 1.5rem;
    border-bottom: 1px solid #444; /* Adds a separator line */
    padding-bottom: 10px; /* Space between title and form */
}

/* Modal Body */
.library-modal-body {
    display: flex;
    flex-direction: column;
}

/* Close Button */
.library-modal-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    color: inherit;
    font-size: 1.5rem; /* Increased size for better visibility */
    cursor: pointer;
}

/* Form Elements */
.library-modal-body label {
    margin-bottom: 5px; /* Space between label and input */
    font-weight: bold; /* Makes labels stand out */
}

.library-modal-body input[type="text"],
.library-modal-body input[type="file"],
.library-modal-body textarea {
    background-color: #444; /* Dark gray background for inputs */
    color: #f4f4f4; /* Light text for readability */
    border: 1px solid #777; /* Slight border for definition */
    border-radius: 4px;
    padding: 8px 10px;
    margin-bottom: 15px; /* Space between inputs */
    font-size: 1rem;
}

.library-modal-body input[type="text"]:focus,
.library-modal-body input[type="file"]:focus,
.library-modal-body textarea:focus {
    outline: none;
    border-color: #999; /* Highlight border on focus */
    background-color: #555; /* Slightly lighter gray on focus */
}

/* Buttons */
.library-modal-body button {
    background-color: rgb(63, 189, 230); /* App's primary blue color */
    color: black; /* White text */
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-top: 10px; /* Space above the button */
}

.library-modal-body button:hover {
    background-color: #2ba9d2; /* Darker blue on hover */
}

.library-modal-body button:focus {
    outline: none;
    background-color: #2ba9d2; /* Darker blue on focus */
}

/* Save and Cancel Buttons (If Applicable) */
.library-modal-body .save-button {
    background-color: rgb(63, 189, 230); /* Blue background for save */
    margin-right: 10px; /* Space between buttons if multiple */
}

.library-modal-body .save-button:hover {
    background-color: #2ba9d2;
}

.library-modal-body .cancel-button {
    background-color: #dc3545; /* Red background for cancel */
}

.library-modal-body .cancel-button:hover {
    background-color: #c82333;
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Responsive Adjustments for Modal */
@media (max-width: 480px) {
    .library-modal-content {
        padding: 20px 25px;
    }

    .library-modal-content h2 {
        font-size: 1.3rem;
        margin-bottom: 20px;
    }

    .library-modal-body button {
        width: 100%; /* Full width buttons on small screens */
        margin-top: 5px;
    }

    .library-modal-body .save-button {
        margin-right: 0;
    }
}
