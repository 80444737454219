/* src/components/style/ProjectDetail.css */

/* --- Container for the Project Detail Page --- */
.project-detail-container {
    max-width: 930px;
    margin: 80px auto 20px auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, color 0.3s ease;
}

.project-detail-container.project-detail-dark {
    background-color: #191A1A;
    color: #ffffff;
}

.project-detail-container.project-detail-light {
    background-color: #f4f4f4;
    color: #333333;
}

/* --- Project Header --- */
.project-detail-project-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

/* --- Title and Description Sections --- */
.project-detail-title-section,
.project-detail-description-section,
.project-detail-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* --- Display Field (Title & Description) --- */
.project-detail-display-field {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* --- Project Title --- */
.project-detail-title {
    margin: 0;
    font-size: 2rem;
    padding-bottom: 10px;
    border-bottom: 2px solid;
}

.project-detail-title.project-detail-title-dark {
    color: rgb(63, 189, 230);
    border-bottom-color: rgb(63, 189, 230);
}

.project-detail-title.project-detail-title-light {
    color: #333333;
    border-bottom-color: #333333;
}

/* --- Project Description --- */
.project-detail-description {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.6;
}

/* --- Inline Edit Container --- */
.project-detail-inline-edit {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* --- Inline Input Fields --- */
.project-detail-inline-input,
.project-detail-inline-textarea {
    padding: 10px;
    border: 1px solid #444;
    border-radius: 4px;
    background-color: #333;
    color: #ffffff;
    font-size: 1rem;
    width: 100%;
}

.project-detail-inline-textarea {
    resize: vertical;
    height: 80px;
}

.project-detail-inline-input.project-detail-light,
.project-detail-inline-textarea.project-detail-light {
    background-color: #ccc;
    color: #333;
    border: 1px solid #aaa;
}

.project-detail-inline-input.project-detail-dark,
.project-detail-inline-textarea.project-detail-dark {
    background-color: #444;
    color: #ffffff;
    border: 1px solid #555;
}

/* --- Inline Save and Cancel Buttons --- */
.project-detail-inline-save-button,
.project-detail-inline-cancel-button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.project-detail-inline-save-button {
    color: #ffffff;
}

.project-detail-inline-cancel-button {
    color: #ff5e57; /* Brighter red for better contrast in dark mode */
}

.project-detail-inline-save-button:hover {
    color: gray;
}

.project-detail-inline-cancel-button:hover {
    color: #e74c3c; /* Slightly darker on hover */
}

/* --- Edit Button --- */
.project-detail-edit-button {
    background-color: #2c3e50;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.project-detail-edit-button:hover {
    background-color: #34495e;
}

/* --- Add Button for Sections --- */
.project-detail-add-button {
    background-color: #2c3e50;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.project-detail-add-button:hover {
    background-color: #34495e;
}

/* --- Add Existing Button Styling --- */
.project-detail-add-existing-button {
    background-color: #2c3e50;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.project-detail-add-existing-button:hover {
    background-color: #34495e;
}

/* --- Flex Container for Document Buttons --- */
.project-detail-doc-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px; /* Adjust spacing as needed */
}

/* Ensure the .project-detail-doc-add-container does not cause stacking */
.project-detail-doc-add-container {
    display: flex;
    align-items: center;
    position: relative; /* Keep position relative for dropdown positioning */
}

/* --- Dropdown Menu for Adding Existing Documents --- */
.project-detail-doc-dropdown {
    position: absolute;
    top: 50px; /* Adjust based on button height */
    right: 0;
    background-color: #333;
    border: 1px solid #555;
    border-radius: 4px;
    width: 250px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
}

.project-detail-doc-dropdown .project-detail-dropdown-item {
    padding: 10px 15px;
    cursor: pointer;
    color: #ffffff;
}

.project-detail-doc-dropdown .project-detail-dropdown-item:hover,
.project-detail-doc-dropdown .project-detail-dropdown-item:focus {
    background-color: #2ba9d2;
    color: #ffffff;
}

/* --- Dropdown Menu for Adding Existing Threads --- */
.project-detail-thread-add-container {
    display: flex;
    align-items: center;
    position: relative;
}

.project-detail-thread-dropdown {
    position: absolute;
    top: 50px; /* Adjust based on button height */
    right: 0;
    background-color: #333;
    border: 1px solid #555;
    border-radius: 4px;
    width: 250px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
}

.project-detail-thread-dropdown .project-detail-dropdown-item {
    padding: 10px 15px;
    cursor: pointer;
    color: #ffffff;
}

.project-detail-thread-dropdown .project-detail-dropdown-item:hover,
.project-detail-thread-dropdown .project-detail-dropdown-item:focus {
    background-color: #2ba9d2;
    color: #ffffff;
}

/* --- Flex Container for Action Buttons --- */
.project-detail-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
}

/* --- Specific Flex Gap for Thread Action Buttons --- */
.project-detail-actions-threads {
    gap: 15px;
}

/* --- Ensure Action Buttons Don't Shrink --- */
.project-detail-action-button {
    white-space: nowrap; /* Prevent button text from splitting */
    font-size: 16px;
    margin-right: 0;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
}

.project-detail-action-button:hover {
    opacity: 0.8;
}

/* --- Specific Styles for Remove and Delete Buttons --- */
/* Dark Mode Styles */
.project-detail-dark .project-detail-remove-button,
.project-detail-dark .project-detail-delete-button {
    color: #ff5e57; /* Brighter red for better contrast */
}

.project-detail-dark .project-detail-remove-button:hover,
.project-detail-dark .project-detail-delete-button:hover {
    color: #e74c3c; /* Slightly darker on hover */
}

/* Light Mode Styles */
.project-detail-light .project-detail-remove-button,
.project-detail-light .project-detail-delete-button {
    color: #c0392b; /* Darker red for better contrast on light background */
}

.project-detail-light .project-detail-remove-button:hover,
.project-detail-light .project-detail-delete-button:hover {
    color: #a93226; /* Slightly darker on hover */
}

/* --- Tables --- */
.project-detail-table {
    width: 100%;
    border-collapse: collapse;
    color: inherit;
    table-layout: fixed; /* Keep columns stable */
}

.project-detail-table-header {
    padding: 10px 15px;
    border-bottom: 1px solid #444;
    text-align: left;
}

.project-detail-table-header.project-detail-table-header-dark {
    background-color: #2c2c2c;
    color: #ffffff;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.project-detail-table-header.project-detail-table-header-light {
    background-color: #f0f0f0;
    color: #333;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/*
  We remove white-space: nowrap; here so the entire cell won't forcibly
  overflow or cause the pencil icon to be truncated. We'll handle truncation
  in a child container that just wraps the text.
*/
.project-detail-table-cell {
    padding: 10px 15px;
    border-bottom: 1px solid #444;
    font-size: 16px;
    overflow: hidden; /* We can keep overflow hidden if we like, but not white-space: nowrap */
    text-overflow: ellipsis;
}

/* 
   The container that has text + pencil icon. We want them side by side,
   and only the text itself to be truncated with ellipses.
*/
.project-detail-rename-container {
    display: flex;
    align-items: center;
    gap: 8px;
}


/* --- Error and Success Messages --- */
.project-detail-error-message,
.project-detail-success-message {
    color: white;
    margin-top: 10px;
    text-align: center;
}

.project-detail-error-message {
    background-color: #e74c3c;
    padding: 10px;
    border-radius: 5px;
}

.project-detail-success-message {
    background-color: #2ecc71;
    padding: 10px;
    border-radius: 5px;
}

/* --- Back Button --- */
.project-detail-back-button {
    display: inline-flex;
    align-items: center;
    margin-top: 30px;
    padding: 10px 20px;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    transition: background-color 0.3s ease;
}

.project-detail-back-button-dark {
    background-color: rgb(63, 189, 230);
    color: #1A1C1C;
}

.project-detail-back-button-light {
    background-color: #ddd;
    color: #333333;
}

.project-detail-back-button:hover {
    background-color: #2ba9d2;
}

.project-detail-back-button svg {
    margin-right: 8px;
}

/* --- Add Document & Thread Forms --- */
.project-detail-add-document-form {
    display: flex;
    flex-direction: column;
}

.project-detail-form-label {
    margin-bottom: 5px;
    font-weight: bold;
}

.project-detail-form-input-file,
.project-detail-form-input-text {
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #444;
    border-radius: 4px;
    background-color: #333;
    color: #ffffff;
}

.project-detail-add-document-form.project-detail-light .project-detail-form-input-file,
.project-detail-add-document-form.project-detail-light .project-detail-form-input-text {
    background-color: #ccc;
    color: #333;
    border: 1px solid #aaa;
}

.project-detail-add-document-form.project-detail-dark .project-detail-form-input-file,
.project-detail-add-document-form.project-detail-dark .project-detail-form-input-text {
    background-color: #444;
    color: #ffffff;
    border: 1px solid #555;
}

.project-detail-form-button {
    padding: 10px;
    border: none;
    border-radius: 0.375rem;
    background-color: rgb(63, 189, 230);
    color: #1e1e1e;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.project-detail-form-button:hover {
    background-color: #2ba9d2;
}

/* --- Visually Hidden Text for Accessibility --- */
.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

/* --- Responsive Adjustments --- */
@media (max-width: 600px) {
    .project-detail-container.project-detail-dark,
    .project-detail-container.project-detail-light {
        padding: 15px;
    }

    .project-detail-title {
        font-size: 1.5rem;
    }

    .project-detail-section-title {
        font-size: 1.2rem;
    }

    .project-detail-table-header,
    .project-detail-table-cell {
        font-size: 14px;
    }

    .project-detail-back-button {
        width: 100%;
        margin-top: 20px;
    }

    .project-detail-inline-input,
    .project-detail-inline-textarea {
        width: 100%;
    }

    .project-detail-thread-dropdown,
    .project-detail-doc-dropdown {
        width: 100%;
        right: auto;
        left: 0;
    }

    /* Adjust button sizes for smaller screens */
    .project-detail-add-button,
    .project-detail-add-existing-button,
    .project-detail-edit-button {
        width: 35px;
        height: 35px;
        font-size: 16px;
    }

    /* Ensure the container remains flex */
    .project-detail-doc-buttons {
        flex-direction: row;
    }

    /* Adjust Actions Column Width */
    .actions-header {
        width: 200px;
        min-width: 150px;
    }

    /* Adjust Action Buttons for Small Screens */
    .project-detail-action-button {
        font-size: 12px;
        padding: 4px 8px;
    }
}

@media (min-width: 769px) {
    /* Additional styles for larger screens if needed */
}
