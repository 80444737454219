/* TechScouting.css */

.tech-scouting-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 80px;
}

/* Dark and Light Mode Styles */
.tech-scouting-container.dark {
  background-color: #191A1A; /* Dark background */
  color: #ffffff; /* White text color */
}

.tech-scouting-container.light {
  background-color: #f4f4f4; /* Light background */
  color: #333333; /* Dark text color */
}

.tech-scouting-container h1 {
  text-align: center;
  height: 60px;
  line-height: 60px;
  margin: 0;
}

.tech-scouting-container h1.dark {
  color: #ffffff;
}

.tech-scouting-container h1.light {
  color: #333333;
}

.tech-scouting-container p {
  text-align: center;
  width: 90%;
  margin: 10px auto 20px auto;
  font-size: 1rem;
}

.tech-scouting-container p.dark {
  color: #ffffff;
}

.tech-scouting-container p.light {
  color: #333333;
}

.tech-scouting-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.tech-scouting-input-container {
  position: relative;
  width: 89%;
  max-width: 890px;
  align-self: center;
  margin-bottom: 20px;
}

.tech-scouting-input-container input[type="text"] {
  padding: 15px 12px 70px 17px;
  margin-top: 24px;
  border: 1px solid #444;
  border-radius: 0.375rem;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1.5;
  outline: none;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  height: auto;
}

.tech-scouting-input-container input[type="text"].dark {
  background-color: #333333; /* Dark mode background color */
  color: #ffffff;
}

.tech-scouting-input-container input[type="text"].light {
  background-color: #f0f0f0; /* Light mode background color */
  color: #333333;
}

.tech-scouting-input-container input[type="text"]:focus {
  border-color: rgb(48, 144, 255);
  box-shadow: 0 0 0 3px rgba(48, 144, 255, 0.3);
}

/* Buttons Container */
.tech-scouting-buttons-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* Button Wrapper */
.tech-scouting-button-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

/* Precision and Library Dropdown Buttons */
.tech-scouting-dropdown-button,
.tech-scouting-library-dropdown-button {
  padding: 8px;
  border: none;
  border-radius: 0.375rem;
  background-color: transparent;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.tech-scouting-dropdown-button.dark,
.tech-scouting-library-dropdown-button.dark {
  color: #ffffff;
}

.tech-scouting-dropdown-button.light,
.tech-scouting-library-dropdown-button.light {
  color: #333333;
}

.tech-scouting-dropdown-button:hover,
.tech-scouting-dropdown-button:focus,
.tech-scouting-library-dropdown-button:hover,
.tech-scouting-library-dropdown-button:focus {
  background-color: #444;
  border-radius: 0.375rem;
}

.tech-scouting-dropdown-button:hover .tech-scouting-tooltip-text,
.tech-scouting-dropdown-button:focus .tech-scouting-tooltip-text,
.tech-scouting-library-dropdown-button:hover .tech-scouting-tooltip-text,
.tech-scouting-library-dropdown-button:focus .tech-scouting-tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Submit Button */
.tech-scouting-submit-button {
  margin-left: auto;
  padding: 5px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tech-scouting-submit-button.dark {
  background-color: rgb(63, 189, 230);
  color: #1e1e1e;
}

.tech-scouting-submit-button.light {
  background-color: #ddd;
  color: #333333;
}

.tech-scouting-submit-button:hover {
  background-color: #2ba9d2;
}

/* Dropdown Menus */
.tech-scouting-dropdown-menu,
.tech-scouting-library-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #444;
  border-radius: 0.375rem;
  z-index: 1000;
  font-size: 16px;
  margin-top: 5px;
  max-height: 200px;
  overflow-y: auto;
  display: none; /* Hidden by default */
}

/* Show dropdown when 'open' class is present */
.tech-scouting-dropdown-menu.open,
.tech-scouting-library-dropdown-menu.open {
  display: block;
}

.tech-scouting-dropdown-menu.dark {
  background-color: #333;
  color: #ffffff;
}

.tech-scouting-dropdown-menu.light {
  background-color: #f0f0f0;
  color: #333333;
}


.tech-scouting-library-dropdown-menu.dark {
  background-color: #333;
  color: #ffffff;
}

.tech-scouting-library-dropdown-menu.light {
  background-color: #f0f0f0;
  color: #333333;
}

/* Dropdown Items */
.tech-scouting-dropdown-item {
  padding: 12px;
  cursor: pointer;
}

.tech-scouting-dropdown-item.dark:hover,
.tech-scouting-dropdown-item.dark:focus {
  background-color: rgb(63, 189, 230);
  color: #202222;
}

.tech-scouting-dropdown-item.light:hover,
.tech-scouting-dropdown-item.light:focus {
  background-color: #ddd;
  color: #333333;
}

/* Tooltip Styling */
.tech-scouting-tooltip-text {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  width: 120px;
  font-size: 12px;
  bottom: 125%; /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
}

.tech-scouting-tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Error Styling */
.tech-scouting-error {
  margin-top: 10px;
  text-align: center;
}

.tech-scouting-error.dark {
  color: white;
}

.tech-scouting-error.light {
  color: #333333;
}

/* Response Styling */
.tech-scouting-response {
  margin-top: 20px;
  text-align: left;
}

.tech-scouting-response.dark {
  color: #ffffff;
}

.tech-scouting-response.light {
  color: #333333;
}

/* Results Header */
.tech-scouting-results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tech-scouting-results-header h2 {
  margin: 0;
  text-align: left;
}

.tech-scouting-results-header h2.dark {
  color: #ffffff;
}

.tech-scouting-results-header h2.light {
  color: #333333;
}

/* Generate Report Button */
.tech-scouting-generate-report-button {
  padding: 10px 20px;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 16px;
}

.tech-scouting-generate-report-button.dark {
  background-color: rgb(63, 189, 230);
  color: #1A1C1C;
}

.tech-scouting-generate-report-button.light {
  background-color: #ddd;
  color: #333333;
}

.tech-scouting-generate-report-button:hover {
  background-color: #2ba9d2;
}

/* Results Container */
.tech-scouting-results-container {
  margin-top: 20px;
}

/* Result Card */
.tech-scouting-result-card {
  border: 1px solid #444;
  border-radius: 0.375rem;
  padding: 20px;
  margin-bottom: 10px;
  text-align: left;
}

.tech-scouting-result-card.dark {
  background-color: #2c2c2c;
  color: #ffffff;
}

.tech-scouting-result-card.light {
  background-color: #fff;
  color: #333333;
}

.tech-scouting-result-card h3 {
  margin-top: 0;
}

.tech-scouting-result-card h3.dark {
  color: rgb(63, 189, 230);
}

.tech-scouting-result-card h3.light {
  color: #333333;
}

.tech-scouting-result-card p,
.tech-scouting-result-card div {
  margin: 0;
  line-height: 1.6;
  text-align: left;
}

/* Visually Hidden (for screen readers) */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
}

/* Skip link styling */
.skip-link {
  position: absolute;
  top: -40px;
  left: 10px;
  background: #000;
  color: #fff;
  padding: 8px;
  z-index: 100;
  text-decoration: none;
  transition: top 0.3s ease;
}

.skip-link:focus {
  top: 10px;
}

/* Report Generated Header */
.tech-scouting-report-generated-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.tech-scouting-report-generated-header.dark {
  color: #ffffff;
}

.tech-scouting-report-generated-header.light {
  color: #333333;
}

/* Download Button */
.tech-scouting-download-button {
  padding: 10px 20px;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 16px;
}

.tech-scouting-download-button.dark {
  background-color: rgb(63, 189, 230);
  color: #1A1C1C;
}

.tech-scouting-download-button.light {
  background-color: #ddd;
  color: #333333;
}

.tech-scouting-download-button:hover {
  background-color: #2ba9d2;
}

/* Slider Styling */
.tech-scouting-slider-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 5px;
  right: 40px;
}

.tech-scouting-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.tech-scouting-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Initial Slider Background for Light Mode */
.tech-scouting-container.light .tech-scouting-slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: #ffffff; /* White background for "Startup" */
  border: 1px solid #888;
  transition: background-color 0.4s, border-color 0.4s;
  border-radius: 12px;
  box-sizing: border-box;
}

/* Slider Background When Checked in Light Mode */
.tech-scouting-container.light input:checked + .tech-scouting-slider {
  background-color: #444444; /* Darker background for "SBIR" */
}

/* Initial Slider Background for Dark Mode */
.tech-scouting-container.dark .tech-scouting-slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: #333; /* Lighter gray background for "Startup" */
  border: 1px solid #888;
  transition: background-color 0.4s, border-color 0.4s;
  border-radius: 12px;
  box-sizing: border-box;
}

/* Slider Background When Checked in Dark Mode */
.tech-scouting-container.dark input:checked + .tech-scouting-slider {
  background-color: #222222; /* Darker background for "SBIR" */
}

.tech-scouting-slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 2px;
  top: 2px;
  background-color: #bbb;
  transition: transform 0.4s;
  border-radius: 50%;
  box-sizing: border-box;
}

input:checked + .tech-scouting-slider:before {
  transform: translateX(20px);
}

.tech-scouting-slider-label {
  margin-left: 10px;
  font-size: 14px;
  width: 70px;
  text-align: center;
  display: inline-block;
}

.tech-scouting-slider-label.dark {
  color: #ffffff;
}

.tech-scouting-slider-label.light {
  color: #333333;
}

/* Reset default button styles for company-button */
.company-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;         /* Inherit font properties */
  color: inherit;        /* Inherit text color */
  text-align: left;      /* Align text to the left */
  cursor: pointer;       /* Show pointer cursor on hover */
  display: inline;       /* Display as inline element */
}

.company-button:hover,
.company-button:focus {
  text-decoration: underline;  /* Underline on hover/focus for visual feedback */
}

/* For Chrome, Safari, and Opera autofill normalization*/
.tech-scouting-input-container input[type="text"].light:-webkit-autofill,
.tech-scouting-input-container input[type="text"].light:-webkit-autofill:hover,
.tech-scouting-input-container input[type="text"].light:-webkit-autofill:focus {
  background-color: #f0f0f0 !important;
  color: #333333 !important;
  -webkit-text-fill-color: #333333 !important;
  transition: background-color 5000s ease-in-out 0s;
}

.tech-scouting-input-container input[type="text"].dark:-webkit-autofill,
.tech-scouting-input-container input[type="text"].dark:-webkit-autofill:hover,
.tech-scouting-input-container input[type="text"].dark:-webkit-autofill:focus {
  background-color: #333333 !important;
  color: #ffffff !important;
  -webkit-text-fill-color: #ffffff !important;
  transition: background-color 5000s ease-in-out 0s;
}

/* For Firefox */
.tech-scouting-input-container input[type="text"].light:-moz-autofill,
.tech-scouting-input-container input[type="text"].light:-moz-autofill:hover,
.tech-scouting-input-container input[type="text"].light:-moz-autofill:focus {
  background-color: #f0f0f0 !important;
  color: #333333 !important;
}

.tech-scouting-input-container input[type="text"].dark:-moz-autofill,
.tech-scouting-input-container input[type="text"].dark:-moz-autofill:hover,
.tech-scouting-input-container input[type="text"].dark:-moz-autofill:focus {
  background-color: #333333 !important;
  color: #ffffff !important;
}

/* For Edge and IE */
.tech-scouting-input-container input[type="text"].light:-ms-autofill,
.tech-scouting-input-container input[type="text"].light:-ms-autofill:hover,
.tech-scouting-input-container input[type="text"].light:-ms-autofill:focus {
  background-color: #f0f0f0 !important;
  color: #333333 !important;
}

.tech-scouting-input-container input[type="text"].dark:-ms-autofill,
.tech-scouting-input-container input[type="text"].dark:-ms-autofill:hover,
.tech-scouting-input-container input[type="text"].dark:-ms-autofill:focus {
  background-color: #333333 !important;
  color: #ffffff !important;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .tech-scouting-buttons-container {
    position: absolute;
    top: 115px;
    left: 5px;
    right: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
  }

  .tech-scouting-container h1 {
    height: auto;
    line-height: normal;
    padding: 10px 0;
  }

  .tech-scouting-button-wrapper {
    margin-right: 10px;
  }

  .tech-scouting-dropdown-button,
  .tech-scouting-library-dropdown-button {
    position: relative;
    bottom: 15px;
    left: 0;
  }

  .tech-scouting-submit-button {
    position: relative;
    bottom: 7px;
    right: 0;
    height: 20px;
    width: 20px;
    font-size: 12px;
  }
  
  .tech-scouting-slider-container {
    display: none;
  }
  
  /* Adjust tooltip max-width for smaller screens */
  .tech-scouting-tooltip-text {
    max-width: 90vw;
  }
}
