/* SignIn.css */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1e1e1e; /* Dark background */
  }

  
  .sign-in-form {
    background: #2c2c2c; /* Slightly lighter dark background for the form */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;
    text-align: center; /* Center the content inside the form */
  }
  
  .sign-in-form img {
    max-width: 200px;
    margin-bottom: 1rem;
  }
  
  .sign-in-form h1 {
    margin-bottom: 1rem;
    color: #ffffff; /* White text color */
  }
  
  .sign-in-form label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #ffffff; /* White text color */
    text-align: left; /* Align labels to the left */
  }
  
  .sign-in-form input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #3a3a3a; /* Dark input background */
    color: #ffffff; /* White text color */
  }
  
  .sign-in-form button {
    width: 100%;
    padding: 0.75rem;
    background-color: #3fbde6; /* Teal background */
    border: none;
    color: #1A1C1C; /* Dark text color */
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .sign-in-form button:hover {
    background-color: #2ba9d2; /* Darker teal on hover */
  }
  
  .error-message {
    color: white;
    margin-top: 1rem;
  }
  
  /* Skip to main content link */
  .skip-link {
    position: absolute;
    top: -40px;
    left: 0;
    background: #000;
    color: #fff;
    padding: 8px;
    z-index: 100;
  }
  
  .skip-link:focus {
    top: 0;
  }
  
  /* Primary navigation landmark */
  .primary-nav {
    display: none; /* Hide navigation landmark if not needed */
  }
  
  /* Visually hidden but accessible for screen readers */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    white-space: nowrap; /* Ensure the label text does not cause overflow */
  }
  
  .success-message {
    color: #3fbde6; /* Teal color for success message */
    margin-top: 1rem;
  }
  
  .confirmation-message {
    color: #ffffff; /* White text color */
    margin-top: 1rem;
  }
  