/* ProjectDashboard.css */

/* Import Animate.css for Animations */
@import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css';

:root {
  --project-dashboard-card-bg-light: #ffffff;
  --project-dashboard-card-bg-dark: #202222;
  --project-dashboard-text-color-light: #333333;
  --project-dashboard-text-color-dark: #f0f0f0;
  --project-dashboard-timeline-line-light: #ccc;
  --project-dashboard-timeline-line-dark: #555;
  --project-dashboard-axis-color: rgba(255, 255, 255, 0.8);
}

.content {
  margin-left: 220px;
}

/* Light/Dark Mode Setup */
.project-dashboard-container.project-dashboard-light {
  color: var(--project-dashboard-text-color-light);
  --project-dashboard-axis-color: rgba(50, 50, 50, 0.8);
}

.project-dashboard-container.project-dashboard-dark {
  color: var(--project-dashboard-text-color-dark);
}

.project-dashboard-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  margin-top: 50px;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background-color: var(--project-dashboard-container-bg);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.project-dashboard-heading {
  font-size: 36px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  animation: fadeInDown 1s;
}

.project-dashboard-funding {
  text-align: center;
  margin-bottom: 20px;
}

.project-dashboard-technology {
  text-align: center;
  margin-bottom: 20px;
}

/* -------------------- TIMELINE (≥1024px) -------------------- */
.project-dashboard-timeline {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 0 10px;
}

.project-dashboard-timeline-list {
  width: 600px;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between; 
  position: relative;
  margin: 0 auto; 
}

.project-dashboard-timeline-list::before {
  content: '';
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;
  height: 2px;
  background: var(--project-dashboard-timeline-line-light);
  transition: background 0.3s ease;
}

.project-dashboard-container.project-dashboard-dark .project-dashboard-timeline-list::before {
  background: var(--project-dashboard-timeline-line-dark);
}

.project-dashboard-timeline-item {
  position: relative;
  width: 25%;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s forwards;
}

/* --- Edit Button Styling to Match ProjectDetail --- */
.project-dashboard-edit-button {
  background-color: #2c3e50;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  margin-left: 8px; /* Optional: adjust spacing from text */
}

.project-dashboard-edit-button:hover {
  background-color: #34495e;
}

/* --- For inline fields (Name, Funding, Technology) --- */
.project-dashboard-inline-display {
  display: inline-flex;
  align-items: center;
  gap: 8px; /* Adjust spacing as needed */
}

/* --- For card-level edit icons (Description & Requirements) --- */
.project-dashboard-card-edit-button {
  position: absolute;
  top: 10px;      /* Adjust vertical offset as needed */
  right: 10px;    /* Adjust horizontal offset as needed */
  background-color: #2c3e50;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.project-dashboard-card-edit-button:hover {
  background-color: #34495e;
}


.project-dashboard-timeline-icon {
  width: 50px;
  height: 50px;
  background: #ffffff;
  border: 3px solid #3498db;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px auto;
  font-size: 20px;
  color: #3498db;
}

.project-dashboard-timeline-content {
  font-size: 16px;
}

/* ----------------------------------------------- */

.project-dashboard-details-row {
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.project-dashboard-detail-col {
  flex: 1 1 45%;
  background-color: var(--project-dashboard-card-bg-light);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 10px 20px 20px 20px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.project-dashboard-container.project-dashboard-dark .project-dashboard-detail-col {
  background-color: var(--project-dashboard-card-bg-dark);
}

.project-dashboard-detail-heading {
  font-size: 20px;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  font-weight: 600;
}

.project-dashboard-detail-text {
  white-space: pre-wrap;
  line-height: 1.6;
  font-size: 15px;
  margin-bottom: 15px;
  margin-left: 40px; /* Indent text to the right */
}

.project-dashboard-text {
  font-size: 18px;
  margin-bottom: 15px;
  line-height: 1.6;
}

.project-dashboard-error-message {
  color: #ff4d4f;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}

.project-dashboard-info-row {
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}

.project-dashboard-info-row-spacing {
  margin-top: 30px;
}

.project-dashboard-info-col {
  flex: 1 1 45%;
  background-color: var(--project-dashboard-card-bg-light);
  padding-left: 60px;
  padding-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.project-dashboard-dark-card {
  background-color: var(--project-dashboard-card-bg-dark);
}

.project-dashboard-light-card {
  background-color: var(--project-dashboard-card-bg-light);
}

.project-dashboard-subheading {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600;
}

.project-dashboard-results-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-bottom: 10px;
  position: relative;
}

/* Axis lines for Tech Scouting & Stakeholders */
.project-dashboard-tech-scouting-list::before,
.project-dashboard-stakeholders-list::before {
  content: '';
  position: absolute;
  left: -20px;  
  top: 0;
  bottom: 0;
  width: 4px;
  background: var(--project-dashboard-axis-color);
  border-radius: 2px;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
}

.project-dashboard-tech-scouting-list::after,
.project-dashboard-stakeholders-list::after {
  content: '';
  position: absolute;
  left: -20px;
  bottom: 0;
  height: 4px;
  width: calc(100% - 30px);
  background: var(--project-dashboard-axis-color);
  border-radius: 2px;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
}

.project-dashboard-result-item {
  margin-bottom: 15px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  background: transparent;
}

.project-dashboard-result-item::before {
  content: '•';
  font-weight: bold;
  font-size: 25px;
  margin-right: 10px;
  color: #3498db;
}

.project-dashboard-result-item:hover {
  background-color: rgba(52, 152, 219, 0.15);
}

.project-dashboard-metadata {
  margin-top: 40px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1s;
}

.project-dashboard-container.project-dashboard-light .project-dashboard-metadata {
  background-color: var(--project-dashboard-card-bg-light);
}

.project-dashboard-container.project-dashboard-dark .project-dashboard-metadata {
  background-color: var(--project-dashboard-card-bg-dark);
}

/* ---------------- Mini Library ---------------- */
.project-dashboard-mini-library-container {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 20px 0 20px 0;
}

.project-dashboard-mini-library-header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.project-dashboard-mini-library-left-group {
  display: flex;
  align-items: center;
  gap: 15px;
}

.project-dashboard-mini-library-title {
  font-size: 24px;
  margin: 0;
  font-weight: 600;
  text-align: left;
}

.project-dashboard-mini-library-toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.project-dashboard-mini-library-toggle-button {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  padding: 8px 12px;
  cursor: pointer;
  transition: color 0.3s ease;
  color: inherit;
}

.project-dashboard-mini-library-toggle-button:hover {
  color: #2ba9d2;
}

.project-dashboard-mini-library-toggle-button.active {
  font-weight: bold;
}

.project-dashboard-mini-library-toggle-separator {
  margin: 0 5px;
  color: inherit;
  font-size: 1rem;
}

.project-dashboard-mini-library-table {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  table-layout: fixed; 
}

.project-dashboard-mini-library-table th,
.project-dashboard-mini-library-table td {
  padding: 10px;
  border-bottom: 1px solid #444;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.project-dashboard-mini-library-table th {
  background-color: #f0f0f0;
  color: #333;
}

.project-dashboard-container.project-dashboard-dark .project-dashboard-mini-library-table th {
  background-color: #2c2c2c;
  color: #ffffff;
}

/* Visually Hidden Utility */
.project-dashboard-visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
}

/* ---------------- PROJECT SUPPORT ---------------- */
.project-dashboard-project-support-form {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-right: 50px;
}

.project-dashboard-project-support-input-container {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

.project-dashboard-project-support-input {
  padding: 8px 10px 40px 10px;
  border: 1px solid #444;
  border-radius: 0.375rem;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.4;
  outline: none;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  height: auto;
}

.project-dashboard-project-support-input.project-dashboard-dark {
  background-color: #333;
  color: #ffffff;
}

.project-dashboard-project-support-input.project-dashboard-light {
  background-color: #f0f0f0;
  color: #333;
}

.project-dashboard-project-support-input:focus {
  border-color: rgb(48, 144, 255);
  box-shadow: 0 0 0 3px rgba(48, 144, 255, 0.3);
}

.project-dashboard-project-support-buttons-container {
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  display: flex;
  align-items: center;
}

.project-dashboard-project-support-button-with-tooltip {
  position: relative;
  display: inline-block;
}

.project-dashboard-project-support-tooltip-text {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px 8px;
  position: absolute;
  z-index: 2000;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  display: block;
  width: max-content;
  max-width: 90vw;
  font-size: 12px;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
}

.project-dashboard-project-support-tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.project-dashboard-project-support-button-with-tooltip:hover .project-dashboard-project-support-tooltip-text,
.project-dashboard-project-support-button-with-tooltip:focus-within .project-dashboard-project-support-tooltip-text {
  visibility: visible;
  opacity: 1;
}

.project-dashboard-project-support-dropdown-button,
.project-dashboard-project-support-library-dropdown-button {
  padding: 4px;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  transition: background-color 0.3s;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.project-dashboard-project-support-dropdown-button.project-dashboard-dark,
.project-dashboard-project-support-library-dropdown-button.project-dashboard-dark {
  background-color: #333;
  color: #ffffff;
}

.project-dashboard-project-support-dropdown-button.project-dashboard-dark:hover,
.project-dashboard-project-support-library-dropdown-button.project-dashboard-dark:hover,
.project-dashboard-project-support-dropdown-button.project-dashboard-dark:focus,
.project-dashboard-project-support-library-dropdown-button.project-dashboard-dark:focus {
  background-color: #444;
}

.project-dashboard-project-support-dropdown-button.project-dashboard-light,
.project-dashboard-project-support-library-dropdown-button.project-dashboard-light {
  background-color: #f0f0f0;
  color: #333;
}

.project-dashboard-project-support-dropdown-button.project-dashboard-light:hover,
.project-dashboard-project-support-library-dropdown-button.project-dashboard-light:hover,
.project-dashboard-project-support-dropdown-button.project-dashboard-light:focus,
.project-dashboard-project-support-library-dropdown-button.project-dashboard-light:focus {
  background-color: #ddd;
}

.project-dashboard-project-support-submit-button {
  margin-left: auto;
  padding: 5px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-dashboard-project-support-submit-button.project-dashboard-dark {
  background-color: rgb(63, 189, 230);
  color: #202222;
}

.project-dashboard-project-support-submit-button.project-dashboard-dark:hover {
  background-color: #2ba9d2;
}

.project-dashboard-project-support-submit-button.project-dashboard-light {
  background-color: #ddd;
  color: #333;
}

.project-dashboard-project-support-submit-button.project-dashboard-light:hover {
  background-color: #bbb;
}

.project-dashboard-project-support-dropdown-menu,
.project-dashboard-project-support-library-dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  border: 1px solid #444;
  border-radius: 0.375rem;
  z-index: 2000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  max-height: 150px;
  overflow-y: auto;
}

.project-dashboard-project-support-dropdown-menu.project-dashboard-dark,
.project-dashboard-project-support-library-dropdown-menu.project-dashboard-dark {
  background-color: #333;
  color: #ffffff;
}

.project-dashboard-project-support-dropdown-menu.project-dashboard-light,
.project-dashboard-project-support-library-dropdown-menu.project-dashboard-light {
  background-color: #f0f0f0;
  color: #333;
}

.project-dashboard-project-support-dropdown-menu {
  left: 0;
}

.project-dashboard-project-support-library-dropdown-menu {
  left: 0;
}

.project-dashboard-project-support-dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.project-dashboard-project-support-dropdown-item.project-dashboard-dark:hover,
.project-dashboard-project-support-dropdown-item.project-dashboard-dark:focus {
  background-color: rgb(63, 189, 230);
  color: #202222;
}

.project-dashboard-project-support-dropdown-item.project-dashboard-light:hover,
.project-dashboard-project-support-dropdown-item.project-dashboard-light:focus {
  background-color: #ddd;
  color: #333;
}

.project-dashboard-project-support-dropdown-menu.closed,
.project-dashboard-project-support-library-dropdown-menu.closed {
  display: none;
}

.project-dashboard-project-support-dropdown-menu.open,
.project-dashboard-project-support-library-dropdown-menu.open {
  display: block;
}

.project-dashboard-project-support-error {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
}

.project-dashboard-project-support-error.project-dashboard-dark {
  color: #fff;
}

.project-dashboard-project-support-error.project-dashboard-light {
  color: #333;
}

.project-dashboard-project-support-error.hidden {
  display: none;
}

.project-dashboard-project-support-error.visible {
  display: block;
}

.project-dashboard-project-support-loading {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

.project-dashboard-project-support-response {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.project-dashboard-project-support-response h2 {
  margin: 10px 0;
  font-size: 16px;
}

.project-dashboard-project-support-download-button {
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
}

.project-dashboard-project-support-download-button.project-dashboard-dark {
  background-color: rgb(63, 189, 230);
  color: #202222;
}

.project-dashboard-project-support-download-button.project-dashboard-dark:hover {
  background-color: #2ba9d2;
}

.project-dashboard-project-support-download-button.project-dashboard-light {
  background-color: #ddd;
  color: #333;
}

.project-dashboard-project-support-download-button.project-dashboard-light:hover {
  background-color: #bbb;
}

.project-dashboard-card-header-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right: 20px;
}

.project-dashboard-search-button {
  margin-top: 23px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background-color: #444;
  color: #f0f0f0;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.project-dashboard-search-button:hover,
.project-dashboard-search-button:focus {
  background-color: #333;
}

.project-library-enlarge-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  line-height: 1;
  color: inherit;
  transition: color 0.3s ease;
}

.project-library-enlarge-button:hover,
.project-library-enlarge-button:focus {
  color: #2ba9d2;
}

.project-support-enlarge-button {
  margin-top: 23px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  line-height: 1;
  color: inherit;
  transition: color 0.3s ease;
}

.project-support-enlarge-button:hover,
.project-support-enlarge-button:focus {
  color: #2ba9d2;
}

/* SSE axis lines hidden if loading */
.project-dashboard-hide-axis::before,
.project-dashboard-hide-axis::after {
  display: none !important;
  content: none !important;
}
/* ---------------- Delete Project Section ---------------- */
.project-dashboard-delete-section {
  /* Remove text-align and align-items to allow full-width button */
  /* text-align: center; */
  
  display: flex;
  flex-direction: column;
  /* align-items: center; */ /* Remove this line to prevent centering */
  
  margin-top: 30px; /* Adds spacing above the section */
  width: 100%; /* Ensure it spans full width */
}

.project-dashboard-delete-button {
  background-color: #e74c3c; /* Bright red background */
  color: #ffffff; /* White text */
  border: none; /* Removes default border */
  padding: 12px 0; /* Vertical padding, no horizontal padding */
  border-radius: 5px; /* Rounds the corners */
  font-size: 16px; /* Increases font size for readability */
  cursor: pointer; /* Changes cursor to pointer on hover */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds subtle shadow */
  
  /* Make the button take full width of the container */
  width: 100%;
  
  /* Remove max-width to allow full width */
  /* max-width: 600px; */ /* Remove or comment out this line */
}

.project-dashboard-delete-button:hover {
  background-color: #c0392b; /* Darker red on hover */
  transform: translateY(2px); /* Slight upward movement on hover */
}

.project-dashboard-delete-button:active {
  transform: translateY(0); /* Resets position on click */
}

.project-dashboard-delete-button:disabled {
  background-color: #bdc3c7; /* Grey background for disabled state */
  cursor: not-allowed; /* Changes cursor to not-allowed */
  opacity: 0.7; /* Reduces opacity */
}

/* ---------------- Error and Success Messages ---------------- */
.project-dashboard-delete-error {
  color: #e74c3c; /* Red color for errors */
  font-weight: bold;
  margin-top: 10px;
  font-size: 14px;
}

.project-dashboard-delete-success {
  color: #2ecc71; /* Green color for success */
  font-weight: bold;
  margin-top: 10px;
  font-size: 14px;
}

/* ---------------- Dark Mode Styles for Delete Button ---------------- */
.project-dashboard-container.project-dashboard-dark .project-dashboard-delete-button {
  background-color: #c0392b; /* Slightly darker red for dark mode */
}

.project-dashboard-container.project-dashboard-dark .project-dashboard-delete-button:hover {
  background-color: #a93226; /* Even darker red on hover in dark mode */
}

.project-dashboard-container.project-dashboard-dark .project-dashboard-delete-error {
  color: #ff6b6b; /* Lighter red for better contrast in dark mode */
}

.project-dashboard-container.project-dashboard-dark .project-dashboard-delete-success {
  color: #27ae60; /* Slightly different green for dark mode */
}

/* ================= Inline Edit Styles ================= */

/* 1) Inline <input> for name, funding, technology */
.project-dashboard-inline-input {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 14px;
  font-family: inherit; /* So it inherits the parent’s font */
  width: auto;
  min-width: 120px; /* Ensures there's enough room even if text is short */
  margin-right: 6px; /* Spacing before the save/cancel buttons */
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.project-dashboard-inline-input:focus {
  border-color: #3498db; /* Light blue border on focus */
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
  outline: none;
}

/* 2) Inline <textarea> for description, requirements, etc. */
.project-dashboard-inline-textarea {
  display: block; /* stacked or inline-block, depending on your preference */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 10px;
  font-size: 14px;
  font-family: inherit;
  width: 100%;
  min-height: 80px;
  margin-bottom: 8px;
  resize: vertical; /* user can resize up/down if you want */
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.project-dashboard-inline-textarea:focus {
  border-color: #3498db;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
  outline: none;
}

.project-dashboard-no-results {
  font-size: 20px;          /* Adjust as desired */
  line-height: 1.4;
  text-align: left;
  margin-top: 80px;
  margin-bottom: 10px;
}


/* 3) Inline Save & Cancel Buttons */
.project-dashboard-inline-save-button,
.project-dashboard-inline-cancel-button {
  background-color: #2c3e50; /* a dark grayish-blue */
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 13px;
  font-family: inherit;
  cursor: pointer;
  margin-right: 5px; /* space between buttons */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.project-dashboard-inline-save-button:hover,
.project-dashboard-inline-cancel-button:hover {
  background-color: #34495e; /* slightly lighter dark color on hover */
}

.project-dashboard-inline-save-button:active,
.project-dashboard-inline-cancel-button:active {
  transform: translateY(1px);
}

/* Optional: If you want distinct colors for Save vs Cancel */
.project-dashboard-inline-save-button {
  background-color: #3498db;
}
.project-dashboard-inline-save-button:hover {
  background-color: #2980b9;
}

.project-dashboard-inline-cancel-button {
  background-color: #95a5a6; /* A grayish alternative for Cancel */
}
.project-dashboard-inline-cancel-button:hover {
  background-color: #7f8c8d;
}


/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ============= RESPONSIVE: Under 1023px ============= */
@media (max-width: 1023px) {
  /* 1) Tech/Stakeholder columns full width, less padding */
  .project-dashboard-info-col {
    flex: 1 1 100%;
    padding-left: 30px;
    padding-right: 15px;
    box-sizing: border-box;
  }

  /* 2) Adjust header row for small screens */
  .project-dashboard-card-header-row {
    margin-right: 0;
    margin-bottom: 10px;
    gap: 20px;
  }
  .project-dashboard-subheading {
    font-size: 16px;
    margin-bottom: 10px;
    max-width: 120px;
  }
  .stakeholders-subheading {
    letter-spacing: -0.5px;
  }

  /* 3) Timeline single row, smaller circles */
  .project-dashboard-timeline {
    overflow-x: hidden;
    padding: 0;
  }
  .project-dashboard-timeline-list {
    width: 100%;
    justify-content: space-between; 
  }
  .project-dashboard-timeline-list::before {
    top: 18px; 
    left: 0;
    right: 0;
  }
  .project-dashboard-timeline-icon {
    width: 28px;
    height: 28px;
    font-size: 14px;
    margin-top: 5px;
  }
  .project-dashboard-timeline-content {
    font-size: 12px;
  }

  /* 4) Shift axis lines to avoid clipping text */
  .project-dashboard-tech-scouting-list::before,
  .project-dashboard-tech-scouting-list::after,
  .project-dashboard-stakeholders-list::before,
  .project-dashboard-stakeholders-list::after {
    left: -20px; 
  }

  /* 5) Mini Library: hide extra columns & toggle, ensure single column is wide with horizontal scroll */
  .project-dashboard-mini-library-table {
    display: block;
    overflow-x: auto;
    width: 100%;         /* let table occupy container width */
    table-layout: fixed; /* keep columns at set widths (we'll define min-width) */
  }

  /* Hide 2nd and 3rd columns (Type, Created At) so only the Name/Prompt remains */
  .project-dashboard-mini-library-table th:nth-child(2),
  .project-dashboard-mini-library-table th:nth-child(3),
  .project-dashboard-mini-library-table td:nth-child(2),
  .project-dashboard-mini-library-table td:nth-child(3) {
    display: none;
  }

  /* Give the 1st column a min-width bigger than 320px so it won't shrink */
  .project-dashboard-mini-library-table th:nth-child(1),
  .project-dashboard-mini-library-table td:nth-child(1) {
    min-width: 170px; /* or 350px, 500px, etc. Adjust to your liking */
  }

  /* Hide the Documents/Threads toggle on small screens */
  .project-dashboard-mini-library-toggle-container {
    display: none;
  }

  /* Slightly smaller text inside the first column if you like */
  .project-dashboard-mini-library-table th,
  .project-dashboard-mini-library-table td {
    font-size: 14px;
    white-space: nowrap; /* keep text on one line if you'd rather scroll horizontally 
                            remove if you prefer wrapping. */
  }

  /* 6) Project Support form adjustments for small screens */
  .project-dashboard-project-support-form {
    flex-direction: column;
    margin-right: 0; 
  }
  .project-dashboard-project-support-input-container {
    margin-bottom: 15px; 
  }
  .project-dashboard-project-support-buttons-container {
    position: static;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;
    justify-content: flex-start;
  }
  .project-dashboard-project-support-input {
    font-size: 14px;
    min-height: 40px; 
  }
  .project-dashboard-project-support-dropdown-button,
  .project-dashboard-project-support-library-dropdown-button,
  .project-dashboard-project-support-submit-button {
    font-size: 12px;
    padding: 4px 6px;
  }
}
