/* Navbar.css */

/* Apply global box-sizing */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Global Styles (For screens above 768px) */
.navbar {
  width: 220px;
  background-color: #202222; /* Dark background */
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items */
  font-family: var(--font-fk-grotesk-neue); /* Apply the font-family */
  position: fixed; /* Fix the navbar to the side */
  height: 100%; /* Make navbar full height */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-logo-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.navbar-logo {
  width: auto;
  max-width: 190px; /* Adjusted max-width to fit within 180px navbar */
  height: auto; /* Ensure the logo scales proportionally */
}

.navbar-menu-toggle-button {
  display: none; /* Hide the menu toggle button on large screens */
}

.navbar-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1; /* Make the menu grow to fill available space */
  margin-top: 100px; /* Adjust margin to fit content */
}

.navbar-menu button {
  background-color: transparent;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  border-radius: 4px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  margin-left: -20px; /* Adjusted margin to prevent overflow */
}

/* Individual project buttons (listed projects) styling */
.navbar-project-button {
  font-size: 16px;
  padding: 10px 20px;
  padding-left: 30px; /* Indent the projects to the right */
  position: relative; /* For positioning the 'L' symbol */
}

/* 'L' symbol on project buttons */
.navbar-project-button::before {
  content: "L";
  position: absolute;
  left: 10px; /* Position the 'L' slightly inside */
  font-size: 14px;
  opacity: 0.8;
  color: rgba(255, 255, 255, 0.8);
}

/* Highlight for active (selected) project button */
.navbar-project-button.active {
  background-color: #2ba9d2;
  color: #1c1c1c !important;
  font-weight: bold;
}

/* Hover effect for all project buttons */
.navbar-menu button:hover {
  background-color: #333333; /* Darker background on hover */
}

/* Projects Header (small screens) */
.navbar-projects-header,
.navbar-projects-header-static {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 20px;   /* Larger font size for "Projects" header */
  font-weight: bold; /* Make it bold */
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 5px; /* Slight space before project list starts */
}

/* Style for the new project button next to Projects header */
.navbar-new-project-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 5px; /* Slight padding around icon */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -5px; /* Move the '+' button up slightly */
}

.navbar-new-project-icon {
  font-size: 18px; /* Size of the plus icon */
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.3s ease;
}

.navbar-new-project-button:hover .navbar-new-project-icon {
  color: #2ba9d2; /* Change color on hover for emphasis */
}

.navbar-menu .navbar-icon {
  margin-right: 10px;
  font-size: 20px;
  opacity: 0.8;
}

/* Icon for library button */
.navbar-menu img.navbar-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  transition: filter 0.3s, opacity 0.3s;
}

/* Dark mode styles for library icon */
.navbar.dark .navbar-menu img.navbar-icon {
  filter: invert(80%);
  opacity: 0.8;
}

/* Light mode styles for library icon */
.navbar.light .navbar-menu img.navbar-icon {
  filter: invert(20%);
  opacity: 0.8;
}

/* Ensure the email box fills the entire width of the navbar */
.navbar-email {
  width: calc(100% + 40px); /* Extend to account for padding in navbar */
  margin-left: -17px; /* Remove margin caused by padding */
  margin-right: -17px;
  display: flex;
  justify-content: space-between; /* Space out email and settings icon */
  align-items: center;
  padding: 15px 6px; /* Reduce left padding to bring email more to the left */
  color: #ffffff;
  font-size: 14px; /* Adjust font size for better visibility */
  border: 1px solid #444444; /* Add border */
  border-right: none; /* Remove right border */
  border-left: none;
  border-radius: 4px 0px 0px 4px; /* Adjust border-radius to avoid right side */
  margin-top: auto; /* Automatically position at the bottom of the navbar */
  margin-bottom: 60px;
  min-height: 60px; /* Ensure the section has a bigger height */
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box; /* Ensure padding is part of the width */
  display: flex;
  justify-content: space-between; /* Space between email info and settings icon */
  align-items: center;
}

.navbar-email:hover {
  background-color: #333333;
}

.navbar-email-info {
  display: flex;
  flex-direction: column; /* Stack email and project name vertically */
  align-items: flex-start;
  flex-grow: 1;
  overflow: hidden;
}

.navbar-email-text {
  overflow: hidden;
  text-overflow: ellipsis; /* Truncate long emails if necessary */
  white-space: nowrap;
  text-align: left; /* Align text to the left */
}

.navbar-default-project-loading,
.navbar-default-project-error {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 4px;
}

/* Light mode styles for the default project name */
.navbar.light .navbar-default-project-name {
  color: rgba(0, 0, 0, 0.9);
}

.navbar.dark .navbar-default-project-error {
  color: #ff4d4f;
}

.navbar.light .navbar-default-project-error {
  color: #d9363e;
}

.navbar-default-project-error {
  color: #ff4d4f;
}

.navbar-default-project-name {
  color: rgba(255, 255, 255, 0.9);
}

.navbar-settings-icon {
  font-size: 17px;
  margin-left: auto; /* Push icon to the right side */
  margin-right: 2px;
  transition: color 0.3s ease; /* Add transition for smooth color change */
}

/* Dark mode styles */
.navbar.dark {
  background-color: #202222;
  color: #ffffff;
}

.navbar.dark .navbar-menu button {
  color: rgba(255, 255, 255, 0.8);
}

.navbar.dark .navbar-menu button:hover {
  background-color: #333333;
}

.navbar.dark .navbar-email {
  color: #ffffff;
  background-color: #202222;
}

.navbar.dark .navbar-email:hover {
  background-color: #333333;
}

.navbar.dark .navbar-settings-icon {
  color: #ffffff; /* White gear icon in dark mode */
}

.navbar.dark .navbar-new-project-icon {
  color: rgba(255, 255, 255, 0.8);
}

.navbar.dark .navbar-new-project-button:hover .navbar-new-project-icon {
  color: #2ba9d2;
}

/* Light mode styles */
.navbar.light {
  background-color: #f4f4f4;
  color: #333;
}

.navbar.light .navbar-menu button {
  color: #333;
}

.navbar.light .navbar-menu button:hover {
  background-color: #ddd; /* Lighter background on hover */
}

.navbar.light .navbar-email {
  color: #333;
  background-color: #f0f0f0;
}

.navbar.light .navbar-email:hover {
  background-color: #ddd;
  color: #000;
}

.navbar.light .navbar-settings-icon {
  color: #333; /* Darker gear icon in light mode */
}

.navbar.light .navbar-new-project-icon {
  color: #333;
}

.navbar.light .navbar-new-project-button:hover .navbar-new-project-icon {
  color: #2ba9d2; /* Still highlight in a blue shade */
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar {
    width: 100%;
    padding: 10px 5px;
    position: fixed;
    top: 0;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: auto;
    overflow: visible;
  }

  .navbar-logo-button {
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
  }

  .navbar-logo {
    height: 35px;
    width: auto;
    max-width: 100%;
    margin: 0;
    padding: 0;
    display: block;
  }

  .navbar-menu-toggle-button {
    display: block;
    flex-shrink: 0;
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.8);
    font-size: 20px;
    cursor: pointer;
    padding: 0;
    margin-right: 5px;
  }

  .navbar-menu-toggle-button .navbar-icon {
    font-size: 20px;
  }

  .navbar-menu {
    display: none;
  }

  .navbar-menu.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #202222;
    padding: 10px;
    z-index: 1001;
    align-items: center;
  }

  .navbar-menu button {
    font-size: 16px;
    padding: 10px 20px;
    margin-left: 0;
    width: 100%;
    text-align: center;
  }

  .navbar-project-button {
    font-size: 14px;
    padding-left: 30px;
  }

  .navbar-project-button::before {
    left: 10px;
    font-size: 12px;
    top: -2px;
  }

  .navbar-projects-header,
  .navbar-projects-header-static {
    justify-content: space-between;
    width: 100%;
    font-size: 18px;
  }

  .navbar-new-project-button {
    margin-left: 10px;
    top: -2px; 
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 10px 5px;
  }

  .navbar-logo {
    height: 30px;
    max-width: 100%;
  }

  .navbar-menu-toggle-button .icon {
    font-size: 18px;
  }

  .navbar-menu button {
    font-size: 14px;
    padding: 8px 10px;
  }

  .navbar-projects-header,
  .navbar-projects-header-static {
    font-size: 16px;
  }

  .navbar-project-button {
    font-size: 12px; 
    padding-left: 30px;
  }

  .navbar-project-button::before {
    font-size: 10px;
    top: -2px;
  }
}
