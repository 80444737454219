/* Base styles */
.ato-documents-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 80px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .ato-documents-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .ato-documents-header-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .ato-documents-header-title-container h1 {
    margin: 0;
    text-align: center;
  }
  
  /* Dark mode */
  .ato-documents-container.dark {
    background-color: #191A1A;
    color: #ffffff;
  }
  
  .ato-documents-header-title-container h1.dark,
  .ato-documents-header-container p.dark {
    color: #ffffff;
  }
  
  /* Light mode */
  .ato-documents-container.light {
    background-color: #f4f4f4;
    color: #333;
  }
  
  .ato-documents-header-title-container h1.light,
  .ato-documents-header-container p.light {
    color: #333;
  }
  
  /* Link buttons */
  .ato-documents-link-buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .ato-documents-link-button {
    background-color: #444;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 14px;
    margin: 0 10px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
  }
  
  .ato-documents-link-button:hover {
    background-color: #666;
  }
  
  .ato-documents-tooltip-text {
    visibility: hidden;
    width: 160px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
  }
  
  .ato-documents-tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .ato-documents-link-button:hover .ato-documents-tooltip-text {
    visibility: visible;
    opacity: 1;
    left: 50%;
  }
  
  /* Error and Success Messages */
  .ato-documents-error-message,
  .ato-documents-success-message {
    color: inherit;
    margin-top: 10px;
    text-align: center;
  }
  
  /* Table */
  .ato-documents-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    color: inherit;
    table-layout: fixed;
  }
  
  .ato-documents-table th,
  .ato-documents-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #444;
  }
  
  .ato-documents-table th:first-child,
  .ato-documents-table td:first-child {
    width: 30%;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }
  
  .ato-documents-table th:last-child,
  .ato-documents-table td:last-child {
    width: 20%;
  }
  
  /* Dark mode table header */
  .ato-documents-table th {
    background-color: #2c2c2c;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Light mode table header */
  .ato-documents-container.light .ato-documents-table th {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .ato-documents-table td button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: 18px;
    margin-right: 12px;
    transition: color 0.3s ease;
  }
  
  .ato-documents-table td button:hover {
    text-decoration: underline;
    color: #2ba9d2;
  }
  
  .ato-documents-no-documents-message {
    text-align: center;
    padding: 20px 0;
  }
  
  /* Responsive Design */
  @media (max-width: 480px) {
    .ato-documents-container {
      padding: 10px 5px;
      margin-top: 20px;
      width: 100%;
    }
  
    .ato-documents-header-title-container {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  
    .ato-documents-header-title-container h1 {
      height: auto;
      line-height: normal;
      padding: 10px 0;
      font-size: 1.5rem;
      text-align: center;
    }
  
    .ato-documents-header-container p {
      font-size: 1rem;
      padding: 5px;
      text-align: center;
      margin-bottom: 15px;
      width: 100%;
    }
  
    .ato-documents-table thead {
      display: none;
    }
  
    .ato-documents-table tbody {
      display: block;
      width: 100%;
    }
  
    .ato-documents-table tr {
      display: block;
      margin-bottom: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid #444;
    }
  
    .ato-documents-table td {
      display: block;
      width: 100%;
      font-size: 1rem;
      padding: 5px 0;
      text-align: left;
      border: none;
    }
  
    .ato-documents-table td[data-label]::before {
      content: attr(data-label);
      font-weight: bold;
      display: block;
      margin-bottom: 5px;
      font-size: 1rem;
    }
  
    .ato-documents-table td button {
      font-size: 1rem;
      margin-right: 0;
      margin-bottom: 5px;
      display: block;
      width: 100%;
      border: none;
    }
  }
  
  /* Light mode adjustments */
  .ato-documents-container.light .ato-documents-error-message,
  .ato-documents-container.light .ato-documents-success-message,
  .ato-documents-container.light th,
  .ato-documents-container.light td {
    color: #333;
  }
  
  .ato-documents-container.light .ato-documents-link-button {
    background-color: #ddd;
    color: #333;
  }
  
  .ato-documents-container.light .ato-documents-link-button:hover {
    background-color: #bbb;
  }
  