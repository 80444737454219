/* StakeholderIdentification.css */

/* Visually Hidden (for screen readers) */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
}

.stakeholder-identification-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 80px;
}

.stakeholder-identification-container.dark {
  background-color: #191a1a;
  color: #ffffff;
}

.stakeholder-identification-container.light {
  background-color: #f4f4f4;
  color: #333333;
}

.stakeholder-identification-container h1 {
  text-align: center;
  height: 60px;
  line-height: 60px;
  margin: 0;
}

.stakeholder-identification-container h1.dark {
  color: #ffffff;
}

.stakeholder-identification-container h1.light {
  color: #333333;
}

.stakeholder-identification-container p {
  text-align: center;
  width: 90%;
  margin: 10px auto 20px auto;
  font-size: 1rem;
}

.stakeholder-identification-container p.dark {
  color: #ffffff;
}

.stakeholder-identification-container p.light {
  color: #333333;
}

.stakeholder-identification-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.stakeholder-identification-input-container {
  position: relative;
  width: 89%;
  max-width: 890px;
  align-self: center;
  margin-bottom: 20px;
}

.stakeholder-identification-input-container input[type='text'] {
  padding: 15px 12px 70px 17px;
  margin-top: 24px;
  border: 1px solid #444;
  border-radius: 0.375rem;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1.5;
  outline: none;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  height: auto;
}

.stakeholder-identification-input-container input[type='text'].dark {
  background-color: #333;
  color: #ffffff;
}

.stakeholder-identification-input-container input[type='text'].light {
  background-color: #f0f0f0;
  color: #333333;
}

/* Placeholder styles for dark and light modes */
.stakeholder-identification-container.dark input::placeholder,
.stakeholder-identification-container.dark textarea::placeholder {
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.3);
}

.stakeholder-identification-container.light input::placeholder,
.stakeholder-identification-container.light textarea::placeholder {
  font-family: 'Inter', sans-serif;
  color: rgba(0, 0, 0, 0.5);
}

.stakeholder-identification-input-container input[type='text']:focus {
  border-color: rgb(48, 144, 255);
  box-shadow: 0 0 0 3px rgba(48, 144, 255, 0.3);
}

/* Dropdown Button */
.stakeholder-identification-dropdown-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 8px;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stakeholder-identification-dropdown-button.dark {
  background-color: #333;
  color: #ffffff;
}

.stakeholder-identification-dropdown-button.light {
  background-color: #ddd;
  color: #333333;
}

.stakeholder-identification-dropdown-button:hover,
.stakeholder-identification-dropdown-button:focus {
  background-color: #444;
}

/* Tooltip Styling */
.stakeholder-identification-tooltip-text {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 8px 12px;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  display: block;
  width: max-content;
  max-width: 90vw;
  font-size: 12px;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
}

.stakeholder-identification-tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.stakeholder-identification-dropdown-button:hover .stakeholder-identification-tooltip-text,
.stakeholder-identification-dropdown-button:focus .stakeholder-identification-tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Submit Button */
.stakeholder-identification-submit-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stakeholder-identification-submit-button.dark {
  background-color: rgb(63, 189, 230);
  color: #202222;
}

.stakeholder-identification-submit-button.light {
  background-color: #ddd;
  color: #333333;
}

.stakeholder-identification-submit-button:hover {
  background-color: #2ba9d2;
}

/* Dropdown Menu */
.stakeholder-identification-library-dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  left: 10px;
  border: 1px solid #444;
  border-radius: 0.375rem;
  z-index: 1000;
  font-size: 16px;
  max-height: 200px;
  overflow-y: auto;
}

.stakeholder-identification-library-dropdown-menu.dark {
  background-color: #333;
  color: #ffffff;
}

.stakeholder-identification-library-dropdown-menu.light {
  background-color: #f0f0f0;
  color: #333333;
}

.stakeholder-identification-dropdown-item {
  padding: 12px;
  cursor: pointer;
}

.stakeholder-identification-dropdown-item.dark:hover,
.stakeholder-identification-dropdown-item.dark:focus {
  background-color: rgb(63, 189, 230);
  color: #202222;
}

.stakeholder-identification-dropdown-item.light:hover,
.stakeholder-identification-dropdown-item.light:focus {
  background-color: #ddd;
  color: #333333;
}

/* Handle open and closed states */
.stakeholder-identification-library-dropdown-menu.closed {
  display: none;
}

.stakeholder-identification-library-dropdown-menu.open {
  display: block;
}

/* Optional: Use opacity and visibility for smoother transitions */
.stakeholder-identification-library-dropdown-menu.closed {
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, height 0.3s ease;
}

.stakeholder-identification-library-dropdown-menu.open {
  opacity: 1;
  visibility: visible;
  height: auto;
  transition: opacity 0.3s ease, visibility 0.3s ease, height 0.3s ease;
}

.stakeholder-identification-response {
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  white-space: pre-wrap;
}

.stakeholder-identification-response.dark {
  color: #ffffff;
}

.stakeholder-identification-response.light {
  color: #333333;
}

.stakeholder-identification-results-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.stakeholder-identification-result-card {
  padding: 20px;
  border: 1px solid #444;
  border-radius: 4px;
}

.stakeholder-identification-result-card.dark {
  background-color: #2c2c2c;
  color: #ffffff;
}

.stakeholder-identification-result-card.light {
  background-color: #ffffff;
  color: #333333;
}

.stakeholder-identification-result-card p,
.stakeholder-identification-result-card div {
  margin: 0;
  line-height: 1.6;
  text-align: left;
}

.stakeholder-identification-error {
  margin-top: 10px;
  text-align: center;
}

.stakeholder-identification-error.dark {
  color: #ffffff;
}

.stakeholder-identification-error.light {
  color: #333333;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .stakeholder-identification-container {
    padding: 10px 5px;
    margin-top: 20px;
    width: 100%;
  }

  .stakeholder-identification-container h1 {
    height: auto;
    line-height: normal;
    padding: 10px 0;
  }

  .stakeholder-identification-input-container {
    padding: 10px 5px;
    margin-top: 5px;
  }

  .stakeholder-identification-input-container input[type='text'] {
    font-size: 14px;
  }

  .stakeholder-identification-submit-button {
    font-size: 12px;
    padding: 5px;
    height: 20px;
    width: 20px;
    bottom: 15px;
  }

  /* Adjust tooltip max-width for smaller screens */
  .stakeholder-identification-tooltip-text {
    max-width: 90vw;
  }
}