.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.loading-spinner {
  position: relative;
  width: 60px;
  height: 60px;
}

.loading-inner-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 47px;
  height: 47px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 4px solid rgb(63, 189, 230);
}

.loading-outer-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translate(-50%, -50%);
}

.loading-rotating-circle {
  width: 100%;
  height: 100%;
  animation: spin 1.5s linear infinite;
}

.loading-rotating-circle svg {
  width: 100%;
  height: 100%;
}

.loading-circle {
  stroke: rgb(63, 189, 230);
  stroke-width: 4;
  fill: none;
  stroke-dasharray: 46.91 11.73;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Visually hidden but accessible for screen readers */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
}
