/* NewProject.css */

/* Container Styles */
.new-project-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 150px; /* Moved content further down */
    font-family: 'Arial', sans-serif; /* Ensure consistent font across the component */
  }
  
  .new-project-container.dark {
    background-color: #191A1A;
    color: #ffffff;
  }
  
  .new-project-container.light {
    background-color: #f4f4f4;
    color: #333333;
  }
  
  /* Heading Styles */
  .question-heading {
    text-align: center;
    margin: 0;
    font-size: 36px; /* Scaled up */
    line-height: 1.2;
    font-family: inherit; /* Use the same font as the container */
  }
  
  .question-heading.dark {
    color: #ffffff;
  }
  
  .question-heading.light {
    color: #333333;
  }
  
  /* Typing Cursor Style */
  .typing-cursor {
    display: inline-block;
    animation: blink 0.7s step-end infinite;
  }
  
  @keyframes blink {
    from,
    to {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  
  /* Form Styles */
  .new-project-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px; /* Added spacing */
  }
  
  .input-container {
    position: relative;
    width: 100%;
    max-width: 700px; /* Scaled up */
  }
  
  /* Input and Textarea Styles */
  
  /* Styles for input fields */
  .input-container input[type="text"],
  .input-container input[type="number"] {
    padding: 12px 80px 12px 20px; /* Reduced padding to decrease height */
    margin-top: 24px;
    border: 1px solid #444;
    border-radius: 9999px; /* Oval shape for smaller inputs */
    width: 100%;
    box-sizing: border-box;
    font-size: 18px; /* Reduced font size */
    line-height: 1.5;
    outline: none;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    font-family: inherit; /* Use the same font as the container */
  }
  
  /* Styles for textareas */
  .input-container textarea {
    padding: 16px 80px 16px 20px; /* Adjusted padding */
    margin-top: 24px;
    border: 1px solid #444;
    border-radius: 12px; /* Moderate rounded corners for textareas */
    width: 100%;
    box-sizing: border-box;
    font-size: 18px; /* Reduced font size */
    line-height: 1.5;
    outline: none;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    resize: none;
    overflow-y: auto;
    font-family: inherit; /* Use the same font as the container */
  }
  
  /* Placeholder Text Styles */
  .input-container input::placeholder,
  .input-container textarea::placeholder {
    color: #999;
    font-family: inherit; /* Use the same font as the container */
    font-size: 18px; /* Match input text size */
  }
  
  /* Remove spinners (arrows) from number input */
  .input-container input[type="number"]::-webkit-outer-spin-button,
  .input-container input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .input-container input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  
  /* Textarea Specific Styles */
  .textarea-container textarea {
    min-height: 130px; /* Adjusted height */
    max-height: 600px;
  }
  
  /* Dark and Light Mode Styles */
  .input-container input[type="text"].dark,
  .input-container input[type="number"].dark,
  .input-container textarea.dark {
    background-color: #333333;
    color: #ffffff;
  }
  
  .input-container input[type="text"].light,
  .input-container input[type="number"].light,
  .input-container textarea.light {
    background-color: #f0f0f0;
    color: #333333;
  }
  
  .input-container input[type="text"]:focus,
  .input-container input[type="number"]:focus,
  .input-container textarea:focus {
    border-color: rgb(48, 144, 255);
    box-shadow: 0 0 0 3px rgba(48, 144, 255, 0.3);
  }
  
  /* Submit Button Styles */
  .submit-button {
    position: absolute;
    right: 15px;
    padding: 4px; /* Slightly reduced padding */
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 18px; /* Reduced font size */
    height: 32px; /* Slightly reduced size */
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Remove bottom positioning */
  .submit-button {
    bottom: auto;
  }
  
  /* Adjusted Submit Button Position for Input Fields */
  .input-container input[type="text"] + .submit-button,
  .input-container input[type="number"] + .submit-button {
    top: 34px; /* Adjusted for alignment with reduced button size */
  }
  
  /* Adjusted Submit Button Position for Textareas */
  .input-container.textarea-container .submit-button {
    top: calc(100% - 50px); /* Positioned near the bottom of the textarea */
  }
  
  /* Dark and Light Mode Styles for Submit Button */
  .submit-button.dark {
    background-color: rgb(63, 189, 230);
    color: #1e1e1e;
  }
  
  .submit-button.light {
    background-color: #ddd;
    color: #333333;
  }
  
  .submit-button:hover {
    background-color: #2ba9d2;
  }
  
  .submit-button:focus {
    outline: none;
  }
  
  .submit-button:active {
    transform: scale(0.95);
  }
  
  /* Visually Hidden (for screen readers) */
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    white-space: nowrap;
  }
  
  /* Responsive Adjustments */
  
  /* For tablets and small desktops */
  @media (max-width: 768px) {
    .new-project-container {
      margin-top: 100px;
    }
  
    .question-heading {
      font-size: 28px;
    }
  
    .input-container {
      max-width: 90%;
    }
  
    .input-container input[type="text"],
    .input-container input[type="number"],
    .input-container textarea {
      font-size: 16px; /* Reduced font size */
      padding: 12px 70px 12px 15px; /* Adjusted padding */
    }
  
    /* Placeholder Text Size */
    .input-container input::placeholder,
    .input-container textarea::placeholder {
      font-size: 16px;
    }
  
    .submit-button {
      height: 28px; /* Further reduced size */
      width: 28px;
      font-size: 16px;
      padding: 4px;
    }
  
    /* Adjust submit button positions */
    .input-container input[type="text"] + .submit-button,
    .input-container input[type="number"] + .submit-button {
      top: 30px; /* Adjusted for alignment */
    }
  
    .input-container.textarea-container .submit-button {
      top: calc(100% - 45px);
    }
  }
  
  /* For mobile devices */
  @media (max-width: 480px) {
    .new-project-container {
      margin-top: 80px;
    }
  
    .question-heading {
      font-size: 24px;
    }
  
    .input-container input[type="text"],
    .input-container input[type="number"],
    .input-container textarea {
      font-size: 14px; /* Reduced font size */
      padding: 10px 60px 10px 12px; /* Adjusted padding */
    }
  
    /* Placeholder Text Size */
    .input-container input::placeholder,
    .input-container textarea::placeholder {
      font-size: 14px;
    }
  
    .submit-button {
      height: 26px; /* Further reduced size */
      width: 26px;
      font-size: 14px;
      padding: 3px;
    }
  
    /* Adjust submit button positions */
    .input-container input[type="text"] + .submit-button,
    .input-container input[type="number"] + .submit-button {
      top: 26px; /* Adjusted for alignment */
    }
  
    .input-container.textarea-container .submit-button {
      top: calc(100% - 40px);
    }
  }
  