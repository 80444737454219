/* ATOAssistant.css */

.ato-assistant-tooltip-text {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the button */
  left: 50%;
  margin-left: -80px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.ato-assistant-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 80px; /* Add margin to move the container down */
  background-color: #191A1A; /* Dark background */
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Dark mode styles */
.ato-assistant-container.dark {
  background-color: #191A1A;
  color: #ffffff;
}

.ato-assistant-container.light {
  background-color: #f4f4f4;
  color: #333;
}

.ato-assistant-header-and-button-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the entire container */
  gap: 20px; /* Adjust gap between the header and button */
  width: 100%; /* Ensure it takes the full width */
  margin-bottom: 10px;
}

.ato-assistant-container h1.dark {
  color: #ffffff;
  margin: 0; /* Remove default margin */
}

.ato-assistant-container h1.light {
  color: #333;
  margin: 0; /* Remove default margin */
}

.ato-assistant-docs-button {
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

.ato-assistant-docs-button.dark {
  background-color: #444;
  color: #ffffff;
}

.ato-assistant-docs-button.dark:hover {
  background-color: #666;
}

.ato-assistant-docs-button.light {
  background-color: #ddd;
  color: #333;
}

.ato-assistant-docs-button.light:hover {
  background-color: #bbb;
}

.ato-assistant-tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.ato-assistant-docs-button:hover .ato-assistant-tooltip-text {
  visibility: visible;
  opacity: 1;
}

.ato-assistant-container p.dark {
  text-align: center;
  color: #ffffff;
}

.ato-assistant-container p.light {
  text-align: center;
  color: #333;
}

.ato-assistant-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px; /* Ensure form starts below header */
}

.ato-assistant-input-container {
  position: relative;
  width: 89%; /* Increase width */
  max-width: 890px;
  align-self: center;
  margin-bottom: 20px;
}

.ato-assistant-input-container input[type="text"] {
  padding: 15px 12px 70px 17px;
  margin-top: 24px;
  border: 1px solid #444; /* Dark border */
  border-radius: 0.375rem;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1.5;
  outline: none;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  height: auto;
}

.ato-assistant-input-container input.dark {
  background-color: #333;
  color: #ffffff;
}

.ato-assistant-input-container input.light {
  background-color: #f0f0f0;
  color: #333;
}

.ato-assistant-input-container input[type="text"]:focus {
  border-color: rgb(48, 144, 255);
  box-shadow: 0 0 0 3px rgba(48, 144, 255, 0.3);
}

.ato-assistant-submit-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ato-assistant-submit-button.dark {
  background-color: rgb(63, 189, 230);
  color: #202222;
}

.ato-assistant-submit-button.dark:hover {
  background-color: #2ba9d2;
}

.ato-assistant-submit-button.light {
  background-color: #ddd;
  color: #333;
}

.ato-assistant-submit-button.light:hover {
  background-color: #bbb;
}

.ato-assistant-response {
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  white-space: pre-wrap; /* Preserve formatting */
}

.ato-assistant-response.dark {
  color: #ffffff;
}

.ato-assistant-response.light {
  color: #333;
}

.ato-assistant-conversation-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  align-items: flex-start;
}

.ato-assistant-message {
  width: 100%;
  text-align: left;
  padding: 10px 10px;
  border: 1px solid #444;
  border-radius: 4px;
  margin-bottom: 10px;
}

.ato-assistant-message.dark {
  background-color: #2c2c2c;
  color: #ffffff;
}

.ato-assistant-message.light {
  background-color: #f0f0f0;
  color: #333;
}

.ato-assistant-message.user.dark {
  background-color: #3a3a3a;
}

.ato-assistant-message.user.light {
  background-color: #eee;
}

.ato-assistant-message p {
  margin: 0;
}

.ato-assistant-error {
  margin-top: 10px;
  text-align: center;
}

.ato-assistant-error.dark {
  color: white;
}

.ato-assistant-error.light {
  color: #333;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
}

/* Updated link styles */
.ato-assistant-message a {
  text-decoration: none;
  color: rgb(63, 189, 230); /* Turquoise color */
}

.ato-assistant-message a:hover {
  text-decoration: underline;
}

.ato-assistant-message a:visited {
  color: rgb(63, 189, 230); /* Ensure visited links remain turquoise */
}

.ato-assistant-document-link {
  text-decoration: none;
  cursor: pointer;
}

/* Removed individual dark and light styles for links since they now share the same color */

.ato-assistant-document-link:hover {
  text-decoration: underline;
}

/* For Chrome, Safari, and Opera autofill normalization*/
.ato-assistant-input-container input[type="text"].light:-webkit-autofill,
.ato-assistant-input-container input[type="text"].light:-webkit-autofill:hover,
.ato-assistant-input-container input[type="text"].light:-webkit-autofill:focus {
  background-color: #f0f0f0 !important;
  color: #333333 !important;
  -webkit-text-fill-color: #333333 !important;
  transition: background-color 5000s ease-in-out 0s;
}

.ato-assistant-input-container input[type="text"].dark:-webkit-autofill,
.ato-assistant-input-container input[type="text"].dark:-webkit-autofill:hover,
.ato-assistant-input-container input[type="text"].dark:-webkit-autofill:focus {
  background-color: #333333 !important;
  color: #ffffff !important;
  -webkit-text-fill-color: #ffffff !important;
  transition: background-color 5000s ease-in-out 0s;
}

/* For Firefox */
.ato-assistant-input-container input[type="text"].light:-moz-autofill,
.ato-assistant-input-container input[type="text"].light:-moz-autofill:hover,
.ato-assistant-input-container input[type="text"].light:-moz-autofill:focus {
  background-color: #f0f0f0 !important;
  color: #333333 !important;
}

.ato-assistant-input-container input[type="text"].dark:-moz-autofill,
.ato-assistant-input-container input[type="text"].dark:-moz-autofill:hover,
.ato-assistant-input-container input[type="text"].dark:-moz-autofill:focus {
  background-color: #333333 !important;
  color: #ffffff !important;
}

/* For Edge and IE */
.ato-assistant-input-container input[type="text"].light:-ms-autofill,
.ato-assistant-input-container input[type="text"].light:-ms-autofill:hover,
.ato-assistant-input-container input[type="text"].light:-ms-autofill:focus {
  background-color: #f0f0f0 !important;
  color: #333333 !important;
}

.ato-assistant-input-container input[type="text"].dark:-ms-autofill,
.ato-assistant-input-container input[type="text"].dark:-ms-autofill:hover,
.ato-assistant-input-container input[type="text"].dark:-ms-autofill:focus {
  background-color: #333333 !important;
  color: #ffffff !important;
}

@media (max-width: 480px) {
  .ato-assistant-container {
    padding: 10px 5px;
    margin-top: 20px;
    width: 100%;
  }

  .ato-assistant-container h1 {
    height: auto;
    line-height: normal;
    padding: 10px 0;
  }

  .ato-assistant-input-container {
    padding: 10px 5px;
    margin-top: 5px;
  }

  .ato-assistant-input-container input[type="text"] {
    font-size: 14px;
  }

  .ato-assistant-submit-button {
    font-size: 12px;
    padding: 5px;
    height: 20px;
    width: 20px;
    bottom: 15px;
  }
}
