/* Base styles */
.library-page {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Library Header */
.library-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.library-title {
  font-size: 2rem;
  font-weight: normal;
  margin: 0;
}

.library-search-input {
  padding: 8px 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 400px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: 400px;
}

.library-page.dark .library-search-input {
  background-color: #8888881a;
  color: #fff;
  border: 1px solid #555;
}

.library-underline {
  width: 100vw;
  border-bottom: 2px solid #3a3a3a;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  bottom: 5px;
}

/* Increase space between underline and content */
.library-main-content {
  display: flex;
  justify-content: space-between;
  gap: 2%;
  margin-top: 30px;
}

/* Tables Container */
.library-tables-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  width: 100%;
}

/* Library Containers */
.library-container {
  min-width: 300px;
  padding: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Adjust the flex basis for documents and projects containers */
.library-container.documents-container {
  flex: 1 1 63%;
}

.library-container.projects-container {
  flex: 1 1 35%;
  margin-top: -1px;
}

/* Header Container */
.library-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.library-header-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.library-header-title-container h2 {
  margin: 0;
  text-align: center;
  font-size: 1.5rem;
}

.library-header-title-container .library-upload-button {
  margin-left: 10px;
}

.library-header-container p {
  margin: 10px 0 0 0;
  text-align: center;
  font-size: 1rem;
  width: 90%;
}

/* Dark mode */
.library-page.dark {
  background-color: #191A1A;
  color: #ffffff;
}

.library-container.dark {
  background-color: #191A1A;
  color: #ffffff;
}

.library-title.dark,
.library-underline.dark,
.library-header-title-container h2.dark,
.library-header-container p.dark {
  color: #ffffff;
}

/* Light mode */
.library-page.light {
  background-color: #f4f4f4;
  color: #333;
}

.library-container.light {
  background-color: #f4f4f4;
  color: #333;
}

.library-title.light,
.library-underline.light,
.library-header-title-container h2.light,
.library-header-container p.light {
  color: #333;
}

/* Upload button */
.library-upload-button {
  background-color: #444;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: background-color 0.3s ease;
}

.library-upload-button:hover {
  background-color: #666;
}

.library-tooltip-text {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.library-tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.library-upload-button:hover .library-tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Error and Success Messages */
.library-error-message,
.library-success-message {
  color: white;
  margin-top: 10px;
  text-align: center;
}

.library-error-message {
  background-color: #e74c3c;
  padding: 10px;
  border-radius: 5px;
}

.library-success-message {
  background-color: #2ecc71;
  padding: 10px;
  border-radius: 5px;
}

/* Forms */
.library-upload-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.library-upload-form label {
  color: inherit;
  margin-bottom: 5px;
}

.library-upload-form textarea {
  resize: vertical;
  height: 80px;
}

.library-upload-form button {
  padding: 10px;
  border: none;
  border-radius: 0.375rem;
  background-color: rgb(63, 189, 230);
  color: #1e1e1e;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.library-upload-form button:hover {
  background-color: #2ba9d2;
}

/* Dark Mode Form Adjustments */
.library-upload-form.dark button {
  background-color: rgb(100, 200, 250);
  color: #1e1e1e;
}

.library-upload-form.dark button:hover {
  background-color: #2ba9d2;
}

/* Add to Project Form Styles */
.library-add-to-project-form {
  display: flex;
  flex-direction: column;
}

.library-add-to-project-form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.library-add-to-project-form select {
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #333; /* Gray background */
  color: #ffffff;
}

.library-add-to-project-form.light select {
  background-color: #ccc; /* Light gray for light mode */
  color: #333;
}

.library-add-to-project-form.dark select {
  background-color: #444;
  color: #ffffff;
  border: 1px solid #555;
}

.library-add-to-project-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: rgb(63, 189, 230);
  color: #1e1e1e;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.library-add-to-project-form button:hover {
  background-color: #2ba9d2;
}

.library-add-to-project-form.dark button {
  background-color: rgb(100, 200, 250);
  color: #1e1e1e;
}

.library-add-to-project-form.dark button:hover {
  background-color: #2ba9d2;
}

/* Table */
.library-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  color: inherit;
  table-layout: fixed;
}

/* Set fixed height for table rows */
.library-table tbody tr {
  height: 100px; /* Adjust as needed */
}

/* Ensure cells do not expand row height */
.library-table td,
.library-table th {
  padding: 0 15px; /* Remove top and bottom padding */
  border-bottom: 1px solid #444;
}

/* Adjust font size to fit within fixed row height */
.library-table td {
  font-size: 16px;
}

/* Adjust button size and margins to fit within row */
.library-table td button {
  font-size: 14px;
  padding: 5px 10px;
  margin: 0 5px;
}

/* Adjust icon size */
.library-rename-icon {
  font-size: 16px;
}

/* Adjust column widths for Documents table */
.library-container.documents-container .library-table th,
.library-container.documents-container .library-table td {
  width: 25%;
}

.library-container.documents-container .library-table th:first-child,
.library-container.documents-container .library-table td:first-child {
  width: 30%;
}

.library-container.documents-container .library-table th:last-child,
.library-container.documents-container .library-table td:last-child {
  width: 20%;
}

/* Adjust column widths for Projects table */
.library-container.projects-container .library-table th,
.library-container.projects-container .library-table td {
  width: auto;
}

.library-container.projects-container .library-table th:first-child,
.library-container.projects-container .library-table td:first-child {
  width: 50%;
}

.library-container.projects-container .library-table th:nth-child(2),
.library-container.projects-container .library-table td:nth-child(2) {
  width: 25%;
}

.library-container.projects-container .library-table th:last-child,
.library-container.projects-container .library-table td:last-child {
  width: 25%;
}

/* Prevent header text wrapping in Projects table */
.library-container.projects-container .library-table th {
  white-space: nowrap;
}

/* Dark mode table header */
.library-table th {
  height: 50px;
  background-color: #2c2c2c;
  color: #ffffff;
  font-weight: bold;
  text-align: left;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Light mode table header */
.library-container.light .library-table th {
  background-color: #f0f0f0;
  color: #333;
}

.library-table td button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 18px;
  margin-right: 12px;
  transition: color 0.3s ease;
}

.library-table td button:hover {
  text-decoration: underline;
  color: #2ba9d2;
}

.library-no-documents-message {
  text-align: center;
  padding: 20px 0;
}

/* Rename icon and tooltip */
.library-rename-icon-container {
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.library-rename-icon {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  transition: color 0.3s ease;
}

.library-rename-icon-container:hover .library-rename-icon {
  color: rgba(173, 216, 230, 1);
}

.library-rename-tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 10px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.library-rename-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.library-rename-icon-container:hover .library-rename-tooltip {
  visibility: visible;
  opacity: 1;
}

/* Flexbox Layout for Name Cell */
.library-name-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.library-name-cell .library-document-title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;
}

/* Ensure table cell positioning is relative for tooltip positioning */
.library-table td[data-label="Name"],
.library-table td[data-label="Prompt"] {
  position: relative;
}

/* Optional: Show Full Name on Hover with Tooltip */
.library-table td[data-label="Name"]:hover::after,
.library-table td[data-label="Prompt"]:hover::after {
  content: attr(title);
  display: block;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  top: 100%;
  left: 0;
  white-space: nowrap;
  z-index: 10;
}

/* Light mode adjustments */
.library-container.light .library-error-message,
.library-container.light .library-success-message,
.library-container.light th,
.library-container.light td {
  color: #333;
}

.library-upload-button.light {
  background-color: #ddd;
  color: #333;
}

.library-upload-button.light:hover {
  background-color: #bbb;
}

/* ATO Button */
.library-ato-documents-button {
  background-color: #444;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0 8px; /* Reduced vertical padding */
  height: 30px; /* Fixed height to match upload button */
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex; /* Added for proper alignment */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  position: relative; /* Added for tooltip positioning */
}

.library-ato-documents-button:hover {
  background-color: #555; /* Slightly darker on hover */
  color: rgba(173, 216, 230, 1); /* Light blue text on hover */
}

.library-ato-documents-button .library-tooltip-text {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.library-ato-documents-button .library-tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.library-ato-documents-button:hover .library-tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Rename Container */
.library-rename-container {
  display: flex;
  align-items: center;
}

.library-rename-container input {
  flex: 1;
  margin-right: 8px;
  padding: 6px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #333; /* Gray background */
  color: #ffffff;
}

.library-rename-container.light input {
  background-color: #ccc; /* Light gray for light mode */
  color: #333;
}

.library-save-button {
  background-color: rgb(63, 189, 230);
  color: #1e1e1e;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
}

.library-save-button:hover {
  background-color: #2ba9d2;
}

/* Dark Mode Adjustments */
.library-page.dark .library-rename-container input {
  background-color: #444;
  color: #fff;
  border: 1px solid #555;
}

.library-page.dark .library-save-button {
  background-color: rgb(100, 200, 250);
  color: #1e1e1e;
}

.library-page.dark .library-save-button:hover {
  background-color: #2ba9d2;
}

/* Toggle Button Styles */
.library-toggle-container {
  display: flex;
  align-items: center;
  margin-top: 10px; /* Move the toggle buttons down slightly */
}

.library-toggle-button {
  background-color: transparent;
  color: inherit;
  border: none;
  font-size: 1rem;
  padding: 8px 12px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.library-toggle-button.active {
  font-weight: bold;
}

.library-toggle-button:hover {
  color: #2ba9d2;
}

/* Separator between toggle buttons */
.library-toggle-separator {
  margin: 0 5px;
  color: inherit;
  font-size: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .library-tables-container {
    flex-direction: column;
  }

  .library-container {
    flex: 1 1 100%;
    margin-top: 20px;
  }

  .library-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .library-search-input {
    margin-top: 10px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  /* Adjust column widths for Projects table on small screens */
  .library-container.projects-container .library-table th,
  .library-container.projects-container .library-table td {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .library-page {
    padding: 10px 5px;
    width: 100%;
  }

  .library-container {
    padding: 10px 5px;
    margin-top: 20px;
    width: 100%;
  }

  .library-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .library-title {
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 10px;
  }

  .library-search-input {
    width: 100%;
    margin-right: 0;
  }

  .library-underline {
    margin-bottom: 20px;
  }

  .library-header-title-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .library-header-title-container h2 {
    height: auto;
    line-height: normal;
    padding: 10px 0;
    font-size: 1.2rem;
    text-align: center;
  }

  .library-header-container p {
    font-size: 1rem;
    padding: 5px;
    text-align: center;
    margin-bottom: 15px;
    width: 100%;
  }

  .library-upload-button {
    width: 30px;
    height: 30px;
    font-size: 12px;
    border-radius: 50%;
    margin-top: 10px;
  }

  .library-table thead {
    display: none;
  }

  .library-table tbody {
    display: block;
    width: 100%;
  }

  .library-table tr {
    display: block;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #444;
    height: auto; /* Remove fixed height for mobile */
  }

  .library-table td {
    display: block;
    width: 100%;
    font-size: 1rem;
    padding: 5px 0;
    text-align: left;
    border: none;
    white-space: normal; /* Allow text wrapping on mobile */
  }

  .library-table td[data-label]::before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
  }

  .library-table td button {
    font-size: 1rem;
    margin-right: 0;
    margin-bottom: 5px;
    display: block;
    width: 100%;
    border: none;
  }

  .library-rename-container input,
  .library-rename-container .library-save-button {
    width: 100%;
    margin-bottom: 5px;
    border: none;
  }

  /* Modal Content Adjustments for Mobile */
  .library-modal-content {
    padding: 15px 20px;
  }

  .library-modal-content h2 {
    font-size: 1.2rem;
  }

  .library-upload-form button,
  .library-add-to-project-form button {
    width: 100%;
    padding: 10px;
  }
}

/* Enhance readability for larger screens */
@media (min-width: 769px) {
  .library-tables-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    width: 100%;
  }

  .library-container {
    min-width: 300px;
  }
}
